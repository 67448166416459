.profile-subscribe {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  column-gap: 3.2rem;
  row-gap: 2rem;

  @media all and (max-width: 1160px) {
     column-gap: 2rem;
  }

  @media all and (max-width: 920px) {
     grid-template: auto / 1fr;
  }

  &__item {
    padding: 4.2rem 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: .6rem;
    background: #000000;
    text-align: center;

     @media all and (max-width: 1160px) {
       padding: 3rem 1.8rem;
     }

     @media all and (max-width: 920px) {
       flex-direction: row;
       text-align: left;
     }

     @media all and (max-width: 576px) {
       align-items: flex-start;
       padding: 2rem 1.8rem;
     }
  }

  &__item-icon {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    vertical-align: top;

    @media all and (max-width: 576px) {
      width: 4rem;
      height: 4rem;
    }
  }

  &__item-info {
    margin-top: 3.1rem;

    @media all and (max-width: 1160px) {
      margin-top: 2rem;
    }

    @media all and (max-width: 920px) {
       margin-top: 0;
       margin-left: 3rem;
    }
    @media all and (max-width: 576px) {
      margin-left: 2rem;
    }
  }

  &__item-title {
    display: inline-block;

    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #FFBC25;

    @media all and (max-width: 1160px) {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

  }

  &__item-desc {
    display: inline-block;
    margin-top: 1.5rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #FAFAFA;

    @media all and (max-width: 1160px) {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

  }
}

.team-profile {
  margin-top: 1.6rem;
}

.aside-profile-team-detail {
  margin-top: 1.6rem;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #ffffff;

    & + & {
      margin-top: .8rem;
    }
  }

  &__name {
    font-weight: 700;
    color: #FFBC25;
  }
}

.aside-join-button {
  padding: 1.7rem 2rem 1.3rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #121212;
  border: none;
  border-radius: .2rem;
  background: #ffbc25;
}

.team-profile-container {
  max-width: 118rem;
  padding: 0 2rem;
  width: 100%;
  margin: 3rem auto;
  display: grid;
  grid-template: auto / 26.3rem 1fr;
  gap: 2rem;

  @media all and (max-width: 992px) {
    gap: 1rem;
    grid-template: auto / 22rem 1fr;
  }

  @media all and (max-width: 640px) {
    grid-template: auto / 1fr;
    margin: 1rem 0 0;
    padding: 0 1.6rem;
  }
}

.team-aside {
  border-radius: .3rem;

  @media all and (max-width: 640px) {
    display: flex;
    justify-content: space-between;
  }

  @media all and (max-width: 500px) {
    flex-direction: column;
    max-width: 34.3rem;
    width: 100%;
    margin: 0 auto;
  }

  &-profile {
    padding: 1rem 2rem;
    border-radius: 2px;
    background: #121212;
    box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);

    @media all and (max-width: 992px) {
      padding: 1rem;
    }

    @media all and (max-width: 640px) {
      max-width: 28.8rem;
    }
    @media all and (max-width: 500px) {
      max-width: unset;
    }

    & + & {
      margin-top: .8rem;
    }

    &-nickname {
      line-height: 21px;
      font-size: 1.8rem;
      font-weight: 500;
      color: #DADADA;
      @include truncate;
    }

    &-name {
      @include truncate;
      color: #DADADA;
      font-size: 1.4rem;
      line-height: 16px;
      font-weight: 300;
      margin-top: .5rem;
    }

    &-avatar {
      margin-top: 1rem;
      width: 100%;
      height: 22.3rem;

      @media all and (max-width: 992px) {
        height: 18rem;
      }

      &-image {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        object-fit: cover;
      }
    }
}

  &-additional {

    @media all and (max-width: 500px) {
     margin-top: 1.6rem;
     display: flex;
     flex-direction: column;
    }
  }

  &-settings-button-wrapper, .aside-join-button-wrapper {
    margin-top: 1rem;
    height: 5.1rem;
    box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
    border-radius: 3px;
    background: #121212;

    &-url {
      height: 100%;
      width: 100%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &-text {
        margin-left: .75rem;
        color: #DADADA;
        line-height: 21px;
        font-size: 1.8rem;
      }
    }
  }

  &-settings-button-wrapper {
    @media all and (max-width: 500px) {
      margin-top: 0;
      order: 1;
    }
  }

  .aside-join-button-wrapper {
    @media all and (max-width: 500px) {
      margin-top: 1.6rem;
      order: 3;
    }
  }

  &-socials {
    background: #121212;
    box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
    border-radius: 2px;
    padding: 1rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    @media all and (max-width: 640px) {
      margin-top: 0;
    }
    @media all and (max-width: 500px) {
      margin-top: 1.6rem;
      order: 2;
    }

    &-unit {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 4.1rem;
      margin-bottom: 1rem;

      &-image {
        margin-right: 1.5rem;
      }

      &-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-title {
          color: rgba(218, 218, 218, 0.5);
          line-height: 16px;
          font-size: 1.4rem;
          font-weight: 300;
        }

        &-sociallogin {
          color: #DADADA;
          line-height: 21px;
          font-size: 1.8rem;
        }
      }
    }
  }
}


.team-profile-content {
    background: #121212;
    box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    height: max-content;

    @media all and (max-width: 576px) {
      margin-top: 2.5rem;
    }

    @media all and (max-width: 375px) {
      width: calc(100% + 3.2rem);
      margin-left: -1.6rem;
    }

    .tabs {

      &__panel {
        background: #1F1F1F;
        border-bottom: 1px solid #3F3F3F;
        padding: 0 3rem;
        display: flex;

        @media all and (max-width: 992px) {
          padding: 0 2rem;
        }
      }

      &__panel-item {
        display: inline-block;
        padding: 1rem 0;
        margin-right: 1.6rem;
        height: 4.1rem;
        background: transparent;
        border: none;
        border-radius: 0;
        font-size: 1.8rem;
        line-height: 2.1rem;
        color: #DADADA;
        border-bottom: .2rem solid transparent;
        box-sizing: border-box;
        cursor: pointer;

        @media all and (max-width: 576px) {
          font-size: 1.6rem;
          line-height: 2rem;
        }

        &:hover {
          color: #FFBC25;
        }

        &--active {
          color: #FFBC25;
          border-bottom-color: #FFBC25;
        }
      }

      &__item {
        display: none;

        &--active {
          display: block;
        }
      }
    }

    &__inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 29.2rem;
      padding: 2rem 3rem;

      @media all and (max-width: 992px) {
        min-height: 25rem;
        padding: 2rem;
      }

      @media all and (max-width: 500px) {
        padding: 1.6rem 0;
      }

      &.inner--empty {
        align-items: center;
        justify-content: center;
      }
    }

    &__message {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #858585;
      text-align: center;

      @media all and (max-width: 992px) {
        font-size: 2rem;
        line-height: 2.4rem;
      }

      @media all and (max-width: 576px) {
        font-size: 1.8rem;
      }
    }

    &-tabs-content {

      flex-grow: 1;

      .profile-content-tabs-teams {
        display: flex;
        flex-direction: column;

        &-unit {
          background: #1F1F1F;
          box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
          border-radius: 2px;
          margin-bottom: 2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__flex-start, &__flex-end {
            display: flex;
            align-items: center;
          }

          &-avatar {
            width: 5.4rem;
            height: 5.4rem;

            &-img {
              width: 100%;
              height: 100%;
            }
          }

          &-infofield {
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 28px;
            color: #DADADA;
            margin-left: 1.5rem;
            flex-shrink: 0;
          }

          &-infofield:after {
            content: ' ';
            height: 2.8rem;
            align-self: center;
            margin-left: 1.5rem;
            border: 0.5px solid #3F3F3F;
          }

          &-infofield__teamname {
            color: #FFBC25;
          }

          &-team-members {
            font-size: 2.4rem;
            line-height: 28px;
            color: #DADADA;
            padding-left: 1.5rem;

            .team-members-icon {
              margin-left: 0.6rem;
            }
          }

          &-exitteam {
            background: #FFBC25;
            border-radius: 2px 2px 2px 2px;
            display: block;
            display: flex;
            align-items: center;
            height: 5.4rem;

            &-logo-game {
              width: 5.4rem;
              height: 5.4rem;
              background-size: cover;
              border: 2px;
            }

            &-text {
              border-left: 1px solid #3F3F3F;
              color: #1F1F1F;
              padding: 0 1rem;
              display: flex;
              font-size: 1.8rem;
              font-weight: 500;
              height: 100%;
              justify-content: center;
              align-items: center;
              // display: block;
            }

            .logo-game-linage {
              background-image: url('../img/linage.svg')
            }
          }

        }

        &-create-team {
          background: #FFBC25;
          border-radius: 2px;
          width: 16.6rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          font-size: 1.4rem;
          transition: 300ms;
          color: #121212;
        }

        &-create-team:hover {
          transition: 300ms;
          opacity: 0.9;
        }
      }
    }
  }

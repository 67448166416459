.reset-password-form {
  width: 100%;
  max-width: 40rem;
  margin: 10rem auto;
  background: #1f1f1f;
  padding: 1.5rem 1.5rem 2.2rem;

  .form {
    display: flex;
    flex-direction: column;
  }
}

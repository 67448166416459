@import "normalize";
@import "fonts";
@import "forms";
@import "colors";
@import "items";
@import "header";
@import "footer";
@import "modals";
@import "index";
@import "news";
@import "setting";
@import "news-list";
@import "account";
@import "content";
@import "stream-slider";
@import "slick-theme";
@import "slick";
@import "tournaments";
@import "tournaments-detail";
@import "team-profile";
@import "chat";
@import "command-list";
@import "profile-subscribe";
@import "subscribe-radio";
@import "reset-password";
@import "404";
@import "./jquery.fancybox.css";
@import "./jquery.nice-select.css";
@import './jquery-ui.css';
@import './jquery-ui.theme.css';


html {
  font-size: 10px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

body {
  height: 100vh;
  background: #181818 url(..//img/main-bg.png) center top / auto no-repeat;
  width: 100%;
  font-family: "Roboto", sans-serif;

  @media all and (max-width: 576px) {
    background: #181818;
  }
}

img{
  vertical-align: 0;
}

button {
  outline: none;
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

::-webkit-scrollbar {
	width: 3px;
	background: #1C1C1C;
}

::-webkit-scrollbar-thumb {
  background: #343434;
  &:active {
    background-color: #FFBC25;
  }
}

@import "media";

.align-right {
  display: flex;
  justify-content: flex-end;
}
.align-center {
  display: flex;
  justify-content: center;
}
.align-left {
  display: flex;
  justify-content: flex-start;
}
.news-description__content img {
    height: 100% !important;
}
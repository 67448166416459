.stream-slider {
  margin-top: 1rem;

  &__slide-preview {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }

  @media all and (max-width: 576px) {
    margin: 1rem 1.6rem 0;
  }

  &__slide {
    padding: 0 1rem;

    @media all and (max-width: 576px) {
      padding: 0 .5rem;
    }
    @media all and (max-width: 375px) {
      padding: 0 .2rem;
    }
  }

  &__slide-img {
    width: 100%;
    vertical-align: top;
  }

  .slick-prev, .slick-next {
        width: 20px;
        height: 20px;
        z-index: 2;

        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            border: 2px solid #F3B220;

            @media all and (max-width: 768px) {
                width: 10px;
                height: 10px;
            }
            @media all and (max-width: 480px) {
                width: 8px;
                height: 8px;
                border: 1px solid #F3B220;
            }
            @media all and (max-width: 375px) {
                width: 6px;
                height: 6px;
            }
        }
    }
    .slick-prev {
        left: 15px;

        @media all and (max-width: 576px) {
          left: -15px;
        }

        &::before {
            border-top: transparent;
            border-right: transparent;
        }
    }
    .slick-next {
        right: 15px;
        @media all and (max-width: 576px) {
          right: -15px;
        }
        &::before {
            border-left: transparent;
            border-bottom: transparent;
        }
    }

}

.back-news {
  display: inline-block;
  padding-top: 8.8rem;

  @media all and (max-width: 1160px) {
    padding-top: 5rem;
    margin-left: 1rem;
    width: max-content;
  }

  @media all and (max-width: 992px) {
    padding-top: 5rem;
  }

  @media all and (max-width: 768px) {
    padding-top: 3rem;
  }

  @media all and (max-width: 576px) {
    padding-top: 2rem;
  }

  @media all and (max-width: 375px) {
    padding-top: 1.4rem;
  }

  &__link {
    display: flex;
    align-items: center;

    &-block {
      width: 15.38px;
      height: 15.38px;
      border: 3px solid #FFBC25;
      box-sizing: border-box;
      transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
      border-right: none;
      border-top: none;

      @media all and (max-width: 576px) {
        border-width: 2px;
      }
    }

    &-text {
      padding-left: .624rem;
    }
  }
}

.news-item {
  margin-top: 2rem;
  width: 100%;
  background: #121212;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 1px;

  .news-wrap {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    padding-right: 1.9rem;

    @media all and (max-width: 480px) {
      padding: 0;
    }

    .news-description {
      width: 79.8rem;

      @media all and (max-width: 992px) {
        width: 100%;
      }

      &__headline {
        display: flex;

        @media all and (max-width: 480px) {
          display: none;
        }

        &-palka {
          width: 1rem;
          background: $t-yellow;
        }

        &-text {
          padding-left: 1.3rem;
        }
      }

      &__title {
        display: none;

        @media all and (max-width: 480px) {
          position: absolute;
          bottom: 1rem;
          left: 0;
          padding: 0 1.5rem;
          display: block;
          font-size: 18px;
          line-height: 21px;
          color: #DADADA;
        }
      }

      &__meta {
        display: flex;
        margin-bottom: 1.5rem;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;


        @media all and (max-width: 480px) {
          display: inline-block;
        }
      }

      &__meta-item {
        display: flex;
        margin-right: 1.2rem;
      }

      &__meta-title {
        font-weight: bold;
        margin-right: 0.7rem;
      }

      &__content {
        margin-top: 2rem;
        background: #1F1F1F;
        border-radius: 3px;
        margin-bottom: 2rem;
        margin-left: 2.3rem;

        @media all and (max-width: 480px) {
          width: 100%;
          margin: 0 0 1.5rem
        }

        &-pic {
          position: relative;

          @media all and (max-width: 480px) {
            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 70%;
              background: linear-gradient(180deg, rgba(137, 137, 137, 0) 49%, rgba(0, 0, 0, .8) 100%);
            }
          }

          &-img {
            display: block;
            width: 100%;
          }
        }

        &-text {
          padding: 1.5rem;


          &-article {
            padding-bottom: 2rem;

            &:last-of-type {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.news-description {
  &__reaction {
    margin-top: 2rem;
  }

  &__survey {
    margin-top: 20px;
  }
}

.news-reaction {
  display: flex;
  color: #fff;
  font-size: 1.4rem;
  padding: 1.5rem;

  &__item {
    margin-right: 6.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.views-counter {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 2.2rem;
    height: 1.9rem;
    background: url(../img/views.svg) no-repeat center/cover;
    margin-right: .7rem;
  }
}

.likes-counter {
  display: flex;
  align-items: center;

  &__button {
    border: none;
    outline: none;
    width: 2.1rem;
    height: 1.8rem;
    margin-right: .7rem;
    background: url(../img/like.svg) no-repeat center/cover;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }

    &--unlike {
      background-image: url(../img/like-inactive.svg);
    }
  }
}

.news-description__content-text {
  p+p {
    margin-top: 1.6rem;
  }

  p {
    font-size: 18px;
    line-height: 24px;

    @media all and (max-width: 992px) {
      font-size: 16px;
      line-height: 22px;
    }

    @media all and (max-width: 768px) {
      font-size: 15px;
      line-height: 18px;
    }

    @media all and (max-width: 576px) {
      font-size: 13px;
      line-height: 18px;
    }
  }

  p img {
    max-width: 100%;
    height: auto;
  }

  h2,
  h3 {
    margin: 2rem 0;
    font-weight: 500;
  }

  h2 {
    font-size: 1.4em;
  }

  h3 {
    font-size: 1.2em;
  }

  iframe,
  video,
  .youtube-embed-wrapper {
    margin-bottom: 1.5rem;
  }

  a {
    color: #dabc76;
    text-decoration: underline;
    transition: .2s;

    &:hover {
      color: #e6be5e;
    }
  }

  ul {
    display: block;
    list-style-type: disc;
    padding-left: 20px;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0;
    }
  }
}

.other-news {
  margin-top: 1.5rem;
  background: #121212;

  @media all and (max-width: 480px) {
    display: none;
  }

  .some-news-description {
    padding: 2rem;
    display: grid;
    grid-template: auto/repeat(4, 1fr);
    column-gap: 2rem;

    @media all and (max-width: 992px) {
      grid-template: auto/repeat(3, 1fr);
    }

    @media all and (max-width: 768px) {
      column-gap: 1rem;
    }

    &__item:nth-of-type(4) {
      @media all and (max-width: 992px) {
        display: none;
      }
    }

    &__item {
      background: #1F1F1F;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1rem;

      &-pic {
        width: 100%;
        height: 14rem;
        object-fit: cover;
        vertical-align: top;
      }

      &-text {
        display: inline-block;
        padding: 1rem;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #DADADA;
        max-width: 23.6rem;
        margin: 0 auto;

        @media all and (max-width: 768px) {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
}

.comments {
  margin-top: 1.5rem;
  background: #121212;
  padding-bottom: 3rem;
  margin-bottom: 3rem;

  &__headline {
    padding-top: 1rem;
  }

  .profile__avatar {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
    }
  }

  .comment-children {
    margin-left: 4rem;
  }

  .comment-block {
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 2rem;

    .form-comment {
      padding-bottom: 1.5rem;
      border: 1px solid rgba(218, 218, 218, 0.5);
      border-top: none;
      border-left: none;
      border-right: none;

      .top-side {
        display: flex;

        &__avatar {
          width: 60px;
          height: 60px;

          img {
            width: 100%;
          }
        }

        &__text-field {
          display: block;
          margin-left: 2rem;
          width: 100%;

          &-item {
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 119px;
            border: none;
            outline: none;
            background: #1F1F1F;
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
            border-radius: 1px;
            padding: 1rem;
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            color: rgba(218, 218, 218, 0.3);
            resize: none;
          }
        }
      }

      &__btn {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 1rem;

        &-item {
          display: block;
          width: 165px;
          height: 30px;
          background: #F3B220;
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          color: #121212;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
    }


  }

  .delete-comment {
    &__btn {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .comment-list {
    margin-left: 3rem;
    width: 81.6rem;
    display: flex;
    flex-direction: column;


    .main-comment {
      margin-top: 1.5rem;

      .header-comment {
        background: #1F1F1F;
        border-radius: 1px 1px 0px 0px;
        padding: .7rem 0 1rem 1rem;
        display: flex;

        .profile {
          display: flex;
          flex-grow: 1;

          .rewiev-wrap {
            padding-left: 1rem;

            &__title {

              &-reply {
                padding-left: 1.5rem;
                display: inline-block;
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;

                color: rgba(218, 218, 218, 0.3);
              }
            }

            &__data-published {
              display: inline-block;
              padding-top: .5rem;
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 16px;

              color: #DADADA;
            }
          }
        }

        .comment-rating {
          padding-right: 1.5rem;
          display: flex;
          align-items: center;
          height: min-content;

          &__plus {

            margin-right: .94rem;
          }

          &__number {
            margin-right: 1.06rem;
          }

          &__minus {
            margin-top: -.7rem;
          }
        }
      }

      .text-comment {
        background: #181818;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 1px 1px;
        border-radius: 1px;
        padding-top: 1rem;

        &__content {
          display: inline-block;
          padding: 1rem;

          &-author {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.subscribe-radio {
  display: none;

  &:checked + label {
    color: #000000;
    background: #FFBC25;

    .subscribe-radio__label-price {
      color: #000000;
    }
  }

  &__label {
    margin-bottom: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.6rem;
    min-height: 6rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: white;
    background: #1F1F1F;
    border: .1rem solid rgba(218, 218, 218, 0.3);
    border-radius: .2rem;
    cursor: pointer;

    @media all and (max-width: 576px) {
      margin-bottom: 1.4rem;
      min-height: 4.8rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      background: lighten(#1F1F1F, 3%);
    }

  }

  &__label-price {
    color: #FFBC25;
  }
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.slider {
  padding-top: 4rem;

  @media all and (max-width: 768px) {
    padding-top: 2rem;
  }
  @media all and (max-width: 480px) {
    padding-top: 1rem;
  }

  &.slick-slider.slick-dotted {
    @media all and (max-width: 576px) {
      margin-bottom: 1rem;
    }
    @media all and (max-width: 375px) {
      margin-bottom: 0;
    }
  }

  .slick-list {
    padding-bottom: .6rem;
  }

  .slick-slide {
    box-shadow: 0 7px 4px -3px rgba(0, 0, 0, .4);
    line-height: 0;
  }

  &__item-img-wrapper {
    position: relative;
    width: 100%;
    height: 42rem;

    @media all and (max-width: 992px) {
      height: 36rem;
      object-fit: cover;
    }

    @media all and (max-width: 768px) {
      height: 30rem;
      object-fit: cover;
    }
    @media all and (max-width: 576px) {
      height: 24rem;
      object-fit: cover;
    }
    @media all and (max-width: 480px) {
      height: 20rem;
      object-fit: cover;
    }

    @media all and (max-width: 375px) {
      height: 14.6rem;
      object-fit: cover;
    }


    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: none;
      width: 100%;
      height: 50%;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 42%);
      z-index: 1;

      @media all and (max-width: 576px) {
        display: block;
      }
    }
  }

  &__item-img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;
    border-radius: .3rem;
  }

  &__item {
    width: 100%;
    position: relative;

    &-logo {
      padding-top: 2rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 2;

      &-pic {
        max-width: 33.6rem;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 200px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;
    }

  }

  &__item-info {
    position: absolute;
    left: 0;
    bottom: 5rem;
    width: 100%;
    padding: 0 4rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    z-index: 2;

    @media all and (max-width: 768px) {
      bottom: 3.6rem;
    }
    @media all and (max-width: 576px) {
      padding: 0 2rem;
      bottom: 2.8rem;
    }
    @media all and (max-width: 375px) {
      bottom: 2rem;
    }
  }

  &__item-text {
    max-width: 51.4rem;

    @media all and (max-width: 768px) {
      max-width: 40rem;
    }
    @media all and (max-width: 576px) {
      max-width: 80%;
    }
  }

  &__item-title {
    font-size: 32px;
    line-height: 37px;
    color: #ffffff;

    @media all and (max-width: 992px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media all and (max-width: 768px) {
      font-size: 20px;
      line-height: 22px;
    }
    @media all and (max-width: 640px) {
      font-size: 18px;
      line-height: 20px;
    }
    @media all and (max-width: 576px) {
      font-size: 16px;
    }
    @media all and (max-width: 375px) {
      font-size: 14px;
      line-height: 16px;
    }
    .text-bg {
      text-decoration: none;
      background: #121212;
      outline: .8rem solid #121212;

      @media all and (max-width: 576px) {
        background: unset;
        outline: none;
      }
    }

    &-link {
      color: inherit;

      &:hover {
        color: inherit;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__item-link {
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #121212;
    background: #FFBC25;
    padding: 1rem 2rem;
    border-radius: .2rem;

    @media all and (max-width: 992px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media all and (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
      padding: .8rem 1.5rem;
    }
    @media all and (max-width: 576px) {
      display: none;
    }
  }

  .slick-prev, .slick-next {
    width: 25px;
    height: 25px;
    z-index: 2;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      border: 3px solid #F3B220;

      @media all and (max-width: 768px) {
        width: 15px;
        height: 15px;
        border: 2px solid #F3B220;
      }
      @media all and (max-width: 576px) {
        width: 10px;
        height: 10px;
        border: 1px solid #F3B220;
      }
      @media all and (max-width: 480px) {
        width: 8px;
        height: 8px;
      }
      @media all and (max-width: 375px) {
        width: 6px;
        height: 6px;
      }
    }
  }

  .slick-prev {
    left: 25px;
    @media all and (max-width: 576px) {
      left: 10px;
    }
    @media all and (max-width: 480px) {
      left: 0;
    }

    &::before {
      border-top: transparent;
      border-right: transparent;
    }
  }

  .slick-next {
    right: 25px;
    @media all and (max-width: 576px) {
      right: 10px;
    }
    @media all and (max-width: 480px) {
      right: 0;
    }

    &::before {
      border-left: transparent;
      border-bottom: transparent;
    }
  }

  .slick-dots {
    position: absolute;
    padding: 0;
    bottom: 1.5rem;

    @media all and (max-width: 768px) {
      bottom: 1rem;
    }
    @media all and (max-width: 375px) {
      bottom: .5rem;
    }

    li {
      margin: 0 1rem;
      width: 6rem;
      height: 1rem;

      @media all and (max-width: 992px) {
        width: 4rem;
      }
      @media all and (max-width: 768px) {
        width: 3rem;
      }
      @media all and (max-width: 576px) {
        margin: 0 .5rem;
        width: 2rem;
        height: .8rem;
      }
      @media all and (max-width: 375px) {
        margin: 0 .25rem;
        width: 1.2rem;
      }

      &:focus {
        outline: none;
      }

      button {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0;

        &:focus {
          outline: none;
        }

        &::before {
          background: #656565;
          width: 100%;
          height: .3rem;
          border-radius: 0;
          border: none;
          opacity: 1;

          @media all and (max-width: 768px) {
            height: .2rem;
          }

          @media all and (max-width: 576px) {
            height: .1rem;
          }

          &:focus {
            outline: none;
          }
        }
      }

      &.slick-active {
        button {
          &::before {
            opacity: 1;
            border: none;
            background: #FFBC25;
          }
        }

      }
    }
  }

}

.content {
  padding: 0 1.5rem 7.5rem;
  width: 100%;
  max-width: 117rem;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 768px) {
    padding-bottom: 4.5rem;
  }

  @media all and (max-width: 576px) {
    padding: 0 0 2.5rem;
  }

  .news {
    margin-top: 0.5rem;
    width: 100%;
  }

  &-topline {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    align-items: center;

    &-title {
      font-size: 2.4rem;
      color: #DADADA;
      line-height: 2.8rem;
      border-left: .5rem solid #F3B220;
      padding-left: .2rem;

      @media all and (max-width: 992px) {
        font-size: 20px;
        line-height: 26px;
      }
      @media all and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
      }
      @media all and (max-width: 576px) {
        font-size: 16px;
        line-height: 20px;
      }

      @media all and (max-width: 375px) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &-seemore {
      display: block;
      cursor: pointer;
      margin-left: 2rem;
      font-size: 1.4rem;
      text-decoration: none;
      color: #169494;
      line-height: 2.8rem;

      @media all and (max-width: 576px) {
        display: none;
      }
    }
  }

  .news-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-leftspace {
    max-width: 75.2rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-topstring {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-unit {
      margin-top: 1.5rem;
      width: 49%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #121212;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
      border-radius: 3px;

      @media all and (max-width: 576px) {
        width: 100%;
      }


      &-image {
        position: relative;
        height: 16.8rem;
        background-color: #000;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .image-label {
          position: absolute;
          left: 1.2rem;
          top: 1rem;
          background-color: #F3B220;
          border-radius: 2px;
          height: 2.5rem;
          width: 8.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 300ms;
          text-transform: uppercase;
          font-size: 1.8rem;
          color: #1F1F1F;
        }
      }

      &-info {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 1.4rem;

        &-title {
          color: #DADADA;
          font-weight: 500;
          line-height: 2.1rem;;
          font-size: 1.8rem;
          width: 100%;
          //  @include truncate;
          margin: 0;
        }

        &-category {
          margin: 0 0 0 1.4rem;
          font-size: 1.4rem;
          color: #169494;
          line-height: 1.8rem;
        }

        &__bottom {
          padding: 0 1.5rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-bottom: 1.3rem;
          color: #A6A6A6;
          font-size: 1.4rem;
          line-height: 1.6rem;
          font-weight: 300;
          margin-top: 2.6rem;

          .content-data {
            font-size: 1.4rem;
            font-weight: 300;
            line-height: 1.6rem;
            color: #A6A6A6;
          }
        }
      }
    }

    &-bottomstring {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;

      &-unit {
        width: 49%;
        display: flex;
        justify-content: space-between;
        height: 12.1rem;

        &-image {
          max-width: 16.6rem;
          width: 100%;
          height: unset;
        }

        &-info-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        }

        .content-leftspace-unit-info {
          flex-direction: column;
          align-self: flex-start;
        }
      }
    }
  }

  &-rightspace {
    max-width: 36.7rem;
    width: 100%;
    background-color: #121212;
    margin-top: 1.5rem;
    border-radius: .3rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1.5rem 1.5rem 3.7rem 1.5rem;

    &-title {
      color: #DADADA;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    &-news-list {
      padding: 0;

      &-unit {
        padding: 0;
        list-style-type: none;
        transition: 300ms;
        margin-top: 2rem;

        &-a {
          display: block;
          display: flex;
          justify-content: space-between;

          &-time {
            color: #DADADA;
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 300;
            margin-right: .5rem;
          }

          &-title {
            color: #DADADA;
            font-size: 1.4rem;
            transition: 300ms;
            line-height: 1.6rem;
          }

          &-title:hover {
            transition: 300ms;
            color: #F3B220;
          }
        }
      }
    }
  }
}

.articles {
  padding-bottom: 7.5rem;
  width: 100%;
  max-width: 114rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-self: center;

  &-topline {
    display: flex;
    justify-content: start;
    align-items: baseline;

    &-title {
      font-size: 2.4rem;
      color: #DADADA;
      height: 2.8rem;
      line-height: 2.8rem;
      border-left: .5rem solid #F3B220;
      padding-left: .2rem;
    }

    &-seemore {
      display: block;
      cursor: pointer;
      margin-left: 2rem;
      font-size: 1.4rem;
      text-decoration: none;
      color: #169494;
      line-height: 2.8rem;
    }
  }

  .articles-wrapper {
    display: flex;
    margin-left: -1%;
    margin-right: -1%;

    .content-leftspace-unit {
      width: 23%;
      margin: 0 1%;
      margin-top: 1.5rem;
      // flex-wrap: wrap;
    }
  }
}

.notarticles {
  background-color: #101010;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 1rem 0;
  margin-bottom: 6.5rem;

  .articles-wrapper {
    margin: unset;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 2rem;
  background: #1F1F1F;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

  &-inner {
    width: 100%;
    max-width: 114rem;
    align-self: center;
    padding-bottom: 2rem;
  }

  &-topline {
    display: flex;
    justify-content: space-between;

    &-wrapper-units {
      width: 70%;
      display: flex;
      justify-content: space-between;
    }

    &-unit {
      width: 25%;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;

      &-title {
        color: rgba(218, 218, 218, 0.5);
        font-size: 1.8rem;
        line-height: 21px;
        padding-bottom: 1rem;
        border-bottom: 0.5px solid rgba(218, 218, 218, 0.5);
      }

      a {
        margin-top: 1rem;
        color: #DADADA;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 21px;

        @media all and (max-width: 576px) {
          font-size: 14px;
          line-height: 18px;
        }
        @media all and (max-width: 375px) {
          margin-top: .8rem;
          font-size: 12px;
          line-height: 14px;
        }
      }

      &-email-wrapper {
        margin-top: 1rem;
      }

      &-socials-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;

        a {
          margin: unset;
          line-height: unset;
          transition: 300ms;
        }

        a:hover {
          transition: 300ms;
          transform: rotate(360deg);
        }
      }
    }

    .footer-logotype {
      width: 25%;
      margin-top: 1rem;

      img {
        width: 100%;
      }
    }
  }

  &-bottomline {
    display: flex;
    background: #1F1F1F;
    border-top: 1px solid rgba(218, 218, 218, 0.25);
    width: 100%;
    justify-content: space-between;
    height: 6rem;
    align-items: center;

    .copy {
      color: #DADADA;
      font-size: 1.8rem;
      line-height: 21px;
      margin: .5rem 2rem;

      @media all and (max-width: 375px) {
        font-size: 1rem !important;
        line-height: 1.2rem;
      }
    }
  }
}

@media all and (max-width: 1145px) {
  .footer-topline-wrapper-units {
    flex-wrap: wrap;
  }

  .footer-inner {
    padding-bottom: unset;
  }

  .footer-topline-unit {
    margin-bottom: 3rem;
  }

  .footer-topline .footer-logotype {
    margin-top: -6rem;
    display: flex;
    align-items: center;
  }

  .footer-bottomline a {
    font-size: 1.4rem;
  }

  .footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media all and (max-width: 1130px) {
  .header {
    .header-wrap {
      padding: 0.6rem 2rem;
    }
  }

  .content-leftspace {
    max-width: unset;
  }

  .content .news-wrapper {
    justify-content: center;
  }

  .articles .articles-wrapper {
    margin: 0;
  }

  .articles .articles-wrapper .content-leftspace-unit {
    width: 48%;
  }
}

@media all and (max-width: 900px) {
  .footer-bottomline {
    flex-direction: column;
    padding-top: 1rem;
  }

  .footer-topline-unit {
    width: 50%;
  }

  .articles-topline, .content-topline {
    padding-left: 0.5rem;
  }
}

@media all and (max-width: 756px) {
  .footer {
    padding-top: 1rem;
    display: flex;
    align-items: center;

    &-inner {
      .footer-topline {
        flex-direction: column;

        &-wrapper-units {
          width: 100%;

          .footer-topline-unit {
            margin: unset;
            display: flex;
            align-items: center;
            width: 100%;

            &-title {
              display: none;
            }

            a {
              display: inline-block;
              margin-right: 1.5rem;
              color: #DADADA;
              margin-bottom: .7rem;
              // font-size: 1.2rem;
              // line-height: 14px;
            }

            &__socials {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 1rem;
              margin-bottom: 1rem;
              padding-top: .3rem;
              border-top: 0.5px solid rgba(218, 218, 218, 0.25);

              .footer-topline-unit-socials-wrapper {
                margin: unset;
              }


              .footer-topline-unit-title {
                margin-right: 1.4rem;
                padding-bottom: unset;
                display: block;

                color: #DADADA;
                font-size: 2.2rem;
                line-height: 4.1rem;

                border: unset;

                @media all and (max-width: 576px) {
                  font-size: 1.8rem;
                  line-height: 2.1rem;
                }
              }

              a {
                margin-bottom: unset;
              }
            }
          }
        }

        .footer-logotype {
          display: none;
        }
      }
    }

    &-bottomline {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: unset;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: .5rem;
      text-align: center;

      &-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        a {
          text-decoration: underline;
          font-size: 1.4rem;
          margin: .3rem 0;
        }
      }

      &-right {
        font-size: 1.4rem;
        margin: .3rem 0;
      }
    }
  }
}

@media all and (max-width: 700px) {
  #overlay .reg-wrap .form-registr__leftside {
    display: none;
  }

  #overlay .reg-wrap .form-registr__rightside .info__tabcontent {
    padding: 1.5rem 0;
  }

  .notarticles {
    .articles-wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media all and (max-width: 500px) {

  .nav-text {
    font-size: 1.3rem;
  }

  .header .header-wrap .left-nav__navigation {
    padding-left: 1.1rem;
    font-size: 1.3rem;
  }

  .content-leftspace-unit-info {
    padding: 0 0.4rem;
    flex-direction: column-reverse;
  }

  .content-leftspace-unit-info-category {
    margin: 0.5rem 0;
  }

  .content-leftspace-unit-info__bottom {
    align-items: flex-start;
  }

}

@media all and (max-width: 400px) {

  .nav-text {
    font-size: 1.4rem;
  }
}

.footer {
  background: #1F1F1F;
  width: 100%;
  margin-top: auto;

  .footer-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__soc {
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;

      &-link {
        padding-top: 2rem;
        display: flex;
        width: 15.2rem;
        justify-content: space-between;

        &-item {
          display: block;
          position: relative;

          &::after {
            top: 0;
            right: -2.5rem;
            position: absolute;
            content: "";
            display: block;
            width: .1rem;
            height: 1.7rem;
            background: rgba(255, 255, 255, 0.2);
          }

          &:last-of-type {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .contacts {
    padding: 2rem 3rem;
    max-width: 186rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    &__leftside {

      &-link {
        padding-right: 4rem;

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    &__rightside {
      cursor: pointer;
    }

  }
}

.header {
  background: #121212;
  z-index: 989;
  width: 100%;
  box-shadow: 0px 2px 4px #0C0C0C;

  @media all and (max-width: 576px) {
    border-bottom: 0.5px solid rgba(218, 218, 218, 0.25);
  }

  .header-wrap {
    padding-top: 1.6rem;
    display: flex;
    align-items: center;
    padding-bottom: 1.6rem;
    justify-content: space-between;

    .left-nav {
      display: flex;
      align-items: center;

      &-toggle {
        display: none;
        margin-right: 1rem;
        cursor: pointer;

        @media all and (max-width: 768px) {
          display: flex;
        }
      }

      &__logo {}

      &__navigation {
        padding-left: 6.1rem;

        @media all and (max-width: 768px) {
          display: none;
        }
      }
    }

    .profile-nav {
      display: flex;
      align-items: center;
      position: relative;

      &__notification {
        display: inline-block;

        &-pic {
          display: block;
          margin-right: 2rem;
        }
      }

      &__userphoto {
        display: inline-block;
        margin-right: 1.4rem;

        @media all and (max-width: 768px) {
          margin-right: .6rem;
        }

        &-pic {
          width: 4rem;
          height: 4rem;

          @media all and (max-width: 768px) {
            width: 2.3rem;
            height: 2.3rem;
          }
        }
      }

      &__dropdown {
        display: inline-block;

        &-pic {
          width: 1.2rem;
          height: .8rem;
        }
      }

      &__submenu {
        display: none;
        position: absolute;
        z-index: 1000;
        width: 183px;
        height: 300px;
        top: 2.6rem;
        right: 0;

        .drop-submenu {
          margin-top: 2rem;

          @media all and (max-width: 768px) {
            margin-top: 0;
          }

          &__item {
            background: #1F1F1F;

            &-move-profile {
              padding: .7rem 0 .7rem 1.1rem;
              display: block;
              background: #121212;
              box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.01);
              border-radius: 2px 2px 0px 0px;
            }

            &-profile {
              padding-left: 1.1rem;
              display: flex;
              flex-direction: column;
              padding-top: .7rem;

              &-link {
                display: inline-block;
                padding-bottom: .6rem;

                &-pic {
                  display: inline-block;
                  margin-right: .6rem;
                }
              }
            }

            &-exit {
              text-align: center;
              padding: .7rem;
              display: block;
              width: 100%;
              background: #121212;
              border-radius: 0px 0px 2px 2px;
            }
          }
        }
      }
    }
  }
}



.mobile-navigation {
  display: none;
  width: 20rem;
  z-index: 900;
  // margin-top: 4.2rem;
  position: fixed;
  height: 100%;
  background: #1F1F1F;
  padding: 5.7rem 1.6rem 0 1.6rem;

    &-title {
      font-size: 1.2rem;
      line-height: 14px;
      color: rgba(218, 218, 218, 0.5);
    }

    &-list {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;

      &-unit {
        margin-bottom: 2.4rem;

        &-a {
          color: #DADADA;
          font-size: 1.4rem;
          line-height: 16px;
        }
      }
    }

    &-socials {
      margin-top: 1.4rem;

      &-title {
        color: #DADADA;
        font-size: 1.4rem;
        line-height: 16px;
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5rem;
      }
    }
}

.user-navigation {
  display: none;
  width: 20rem;
  z-index: 900;
  // margin-top: 4.2rem;
  position: fixed;
  height: 100%;
  background: #1F1F1F;
  right: 0;
  padding-top: 3.3rem;

  &-profile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 1.6rem 1.5rem 1.6rem;

    &-image {
      height: 2.9rem;
      width: 2.9rem;
    }

    &-name {
      color: #DADADA;
      font-size: 1.4rem;
      line-height: 16px;
      margin-left: 1rem;
    }
  }

  &-profile-list {
    &-unit {
      padding: 1.2rem 1.6rem 1.2rem 1.6rem;
      border-top: 0.5px solid rgba(218, 218, 218, 0.25);
      display: flex;
      align-items: center;
      &-a {
        color: #DADADA;
        font-size: 1.4rem;
        line-height: 16px;
      }
    }
  }
}

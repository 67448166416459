.profile-settings {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;

    .left-bar {
        max-width: 26.3rem;
        width: 100%;

        .user-profile {
            background: #121212;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            max-width: 26.3rem;
            width: 100%;

            .user-item {
                max-width: 23.3rem;
                width: 100%;
                margin: 0 auto;
                padding-bottom: .8rem;

                &__nickname {
                    padding-top: 1.5rem;
                    padding-bottom: 1rem;
                }

                &__avatar {

                    &-pic {
                        width: 100%;
                        border-radius: 3px;
                    }
                }

                &__status {

                    .online {
                        padding-top: 1.2rem;
                        display: flex;
                        justify-content: center;

                        &__text {
                            position: relative;

                            &::after {
                                width: 1rem;
                                height: 1rem;
                                background: #EC203D;
                                position: absolute;
                                content: "";
                                border-radius: 50%;
                                top: .2rem;
                                right: -1.4rem;
                            }
                        }
                    }
                }
            }
        }

        .user-set {
            margin-top: 2rem;
            background: #121212;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 3px;


            .setting-lists {
                max-width: 22.3rem;
                margin: 0 auto;
                width: 100%;

                &__point {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 2rem 0;
                    border-bottom: 1px solid #F0F1F5;

                    &:hover {
                        color: $t-yellow;

                        svg path {
                            fill: #F3B220;
                        }

                    }

                    &:last-of-type {
                        border-bottom: 0;
                    }

                    &-text {
                        padding-left: 1rem;
                        width: 100%;
                        height: max-content;
                    }
                }
            }
        }
    }

    .right-bar {
        max-width: 84.8rem;
        width: 100%;

        &__headline {
            background: #121212;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            border-radius: 1px 1px 0px 0px;
            border-bottom: 1px solid rgba(240, 241, 245, 0.5);
            ;

            &-text {
                padding: 1rem 0 1rem 2rem;
            }
        }

        .setting-content {

            .info-header {
                background: #1F1F1F;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                border-radius: 0px 0px 1px 1px;
                display: flex;

                &__tab {
                    padding-top: 1rem;
                    padding-bottom: 1rem;

                    &:first-of-type {
                        padding-left: 2rem;
                    }

                    &:last-of-type {
                        padding-left: 3rem;
                    }
                }
            }

            .setting-tab {
                margin-top: 1rem;
                background: #121212;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                display: grid;
                grid-template: auto/ 2fr minmax(25rem, 25rem);
                grid-gap: 1.5rem;
                padding-left: 2rem;
                padding-right: 4.8rem;

                .personal-data {
                    padding-top: 2rem;

                    display: block;

                    &__headline {
                        display: flex;
                        align-items: center;

                        &-text{
                            padding-left: .7rem;
                        }
                    }

                    .list-input{
                        display: grid;
                        grid-template: auto/1fr 1fr;
                        grid-gap: 1.5rem;

                        @media all and (max-width: 900px) {
                            grid-template: auto / 1fr;
                        }
                    }
                }

                .social{

                }
            }
        }
    }
}

.content {
  &__main-title {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #DADADA;
    padding: 1.5rem 0;
  }
}

.content-tabs {
  @media all and (max-width: 720px) {
    order: 2;
  }

  &__tab-panel {
    display: none;

    @media all and (max-width: 720px) {
      display: flex;
    }
  }

  &__tab-list {
    width: 100%;
    display: flex;
  }

  &__tab-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.6rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #DADADA;
    border-bottom: 1px solid #3F3F3F;
    background: #121212;

    @media all and (max-width: 375px) {
      height: 2.6rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    &.tab-item--active {
      border-bottom: 1px solid #FFBC25;
    }
  }

  &__tab-content {

    .content-section {
      @media all and (max-width: 720px) {
        display: none;
      }

      &.section--active {
        display: block;
      }
    }
  }
}

.content-section {

  &.section--articles {
    margin-top: 3.5rem;

    @media all and (max-width: 720px) {
      margin-top: 0;
    }

    .content-section__item {
      &.item--newest {
        display: none;

        @media all and (max-width: 720px) {
          display: flex;
        }
      }
    }

    .content-section__item:nth-of-type(1)  {
      grid-area: 1 / 1 / 3 / 2;
    }

    .content-section__item:nth-of-type(2)  {
      grid-area: 1 / 2 / 3 / 3;

      @media all and (max-width: 992px) {
        display: none;
      }
    }

    .content-section__item:nth-of-type(4)  {
      @media all and (max-width: 992px) {
        display: flex;
      }
      @media all and (max-width: 720px) {
        display: none;
      }
    }
  }

  &.section--streams {
    margin-top: 3.5rem;
    padding: 1.5rem 0;
    background: #121212;
    box-shadow: .2rem .4rem .4rem rgba(0, 0, 0, 0.5);

    @media all and (max-width: 720px) {
      order: 1;
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .content-section__header {
      display: flex;
    }


  }

  &__header {
    display: flex;
    align-items: flex-end;

    @media all and (max-width: 720px) {
      display: none;
    }
  }

  &__title {
    padding-left: .7rem;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #DADADA;

    border-left: .5rem solid #FFBC25;
  }

  &__page-link {
    margin-left: 2rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #169494;

    @media all and (max-width: 576px) {
      display: none;
    }
  }

  &__grid {
    margin-top: 1.5rem;
    display: grid;
    grid-template: auto / repeat(3, 1fr) ;
    column-gap: 2rem;
    row-gap: 1.5rem;

    @media all and (max-width: 992px) {
      grid-template: auto / repeat(2, 1fr) ;
    }
    @media all and (max-width: 720px) {
      margin-top: 0;
      grid-template: auto / 1fr;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #121212;
    border-radius: .3rem;
    box-shadow: .2rem .4rem .4rem rgba(0, 0, 0, 0.5);

    @media all and (max-width: 720px) {
       display: none;
    }

    &.item--newest {
      padding: 1.5rem;
      grid-area: 1 / 3 / 3 / 4;

      @media all and (max-width: 992px) {
        grid-area: 1 / 2 / 3 / 3;
      }
      @media all and (max-width: 720px) {
        display: flex;
        grid-area: unset;
        padding-bottom: 0;
      }

      .content-section__title {
        padding: 0;
        font-weight: 500;
        border: none;

        @media all and (max-width: 720px) {
          display: none;
        }
      }
    }

    &:nth-of-type(even) {
      @media all and (max-width: 992px) {
        display: none;
      }
    }

    &:nth-of-type(3), &:nth-of-type(4)  {
      flex-direction: row;

      .content-section__item-img {
        width: 16.6rem;
        height: 100%;
      }

      .content-section__item-info {
        padding: .5rem 1.5rem 0.5rem 1rem;
      }

      .content-section__item-title-wrapper {
        flex-direction: column;
      }

      .content-section__item-title {
        order: 2;
        margin-top: 1rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
      .content-section__item-category {
        order: 1;
        text-align: unset;
      }
      .content-section__item-comments {
        display: none;
      }
    }

  }

  &__item-img {
    vertical-align: top;
    width: 100%;
    height: 16.8rem;
    object-fit: cover;
    border-radius: .3rem;
  }

  &__item-info {
    flex: 1;
    min-height: 8.8rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem 1rem 1rem;
  }

  &__item-title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

  }

  &__item-title {
    min-height: 4.2rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #DADADA;
    transition: .2s;

    &:hover {
      color: #FFBC25;
    }
  }

  &__item-category {
    min-width: 9rem;
    text-align: right;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #169494;
  }

  &__item-footer {
    width: 100%;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #A6A6A6;
  }

  &__item-comments {
    min-width: max-content;
    padding-left: 2.5rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 2rem;
      height: 100%;
      background: url("../img/comment.svg") center center/contain no-repeat;
    }
  }

  &__newest-list {
    margin-top: 2rem;
    list-style: none;
    @media all and (max-width: 375px) {
      margin-top: 1.5rem;
    }

    .newest__item {
      margin-bottom: 2rem;
      display: flex;
      color: #DADADA;

      @media all and (max-width: 375px) {
        margin-bottom: 1rem;
      }

      &-date {
        font-weight: 300;
        margin-right: .5rem;
      }
      &-link {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #DADADA;
        transition: .2s;

        &:hover {
          color: #FFBC25;
        }
      }
    }
  }

  &__item-marker {
    position: absolute;
    top: 1rem;
    left: 1.2rem;
    display: inline-block;
    padding: .2rem 1.2rem;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-transform: uppercase;
    color: #1F1F1F;
    background: #FFBC25;
    border-radius: .2rem;
  }

  &__item-more-link {
    margin: 1rem auto 0;
    display: none;
    padding: .5rem 0;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #169494;
    border-bottom: 1px solid #FFBC25;

    @media all and (max-width: 720px) {
      display: block;
    }
  }
}

.content-wrapper {
  &.wrapper--center {
    display: flex;
    justify-content: center;
  }
}

.section--offer {
  margin-top: 3.5rem;
  padding: 1.5rem 0;
  background: #121212;
  box-shadow: .2rem .4rem .4rem rgba(0, 0, 0, 0.5);

  @media all and (max-width: 720px) {
    order: 1;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .content-section__header {
    display: flex;
  }

  .content-section__content {
    padding: 1.5rem;
    color: #dadada;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 22px;
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 999;
  -webkit-animation: fade .6s;
  -moz-animation: fade .6s;
  animation: fade .6s;
  overflow: hidden;
}

.reg-wrap {

    .form-registr {
      display: flex;
      min-height: 37.8rem;

      &__leftside {
        background: url(../img/login.jpg) center center/cover no-repeat;
        width: 327px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media all and (max-width: 720px) {
          display: none;
        }
      }

      &__rightside {
        display: flex;
        flex-direction: column;

        .info {
          background: $form-center;
          height: 100%;
          display: flex;
          flex-direction: column;

          &__forget-password {
            font: 14px Roboto, sans-serif;
            color: #DADADA;

          }

          &__header {
            padding: 1rem 0 0;
            padding-left: 3rem;
            width: 327px;
            background: $footer;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 0px 3px 0px 0px;
            position: relative;

            @media all and (max-width: 375px) {
              width: 320px;
            }


            &-tab {
              position: relative;
              margin-right: 4rem;
              padding-bottom: 1rem;
              cursor: pointer;


              &:after {
                display: block;
                content: "";
                position: absolute;
                width: .1rem;
                height: 1.6rem;
                top: 0;
                right: -2.5rem;
                background: rgba(255, 255, 255, 0.5);
              }

              &:last-of-type {
                margin-right: 0;

                &::after {
                  display: none;
                }
              }
            }

            &-close {
              position: absolute;
              right: 1rem;
              border: none;
              outline: none;
              background: transparent;
              cursor: pointer;
              width: 14px;
              height: 14px;
              top: calc(50% - 7px);
              padding: 0;
            }
          }

          &__tabcontent {
            padding: 1rem 0;
            width: 26.6rem;
            margin: 0 auto;
            display: none;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;

            .entry,
            .registr {

              .join,
              .list {
                display: flex;
                flex-direction: column;


                &__item {
                  display: flex;
                  flex-direction: column;
                  padding-bottom: .5rem;
                  position: relative;

                  &:last-of-type {
                    padding-bottom: 0;
                  }

                  &-text {
                    display: block;
                  }
                }
              }

              .check {
                display: flex;
                align-items: center;
                padding-top: 1rem;

                &__checkbox {
                  display: none;

                  &:checked + .check__text {
                    &:before {
                      background: #FFB946 url(../img/check.svg) center center / 80% no-repeat;

                    }
                  }
                }

                &__text {
                  width: 100%;
                  position: relative;
                  padding-left: 2.4rem;


                  &::before {
                    content: "";
                    display: block;
                    border-radius: 0;
                    -webkit-appearance: none;
                    top: -.1rem;
                    left: 0;
                    width: 1.5rem;
                    height: 1.5rem;
                    border: 0.5px solid #FFFFFF;
                    position: absolute;

                  }
                }
              }
            }

            &-link {
              margin-top: 2rem;
              width: fit-content;

              &:hover {
                text-decoration: underline;
              }
            }

            .registr {

              .list {
                padding-top: 1rem;
              }

              .check {

                &__text {
                  padding-left: 2.6rem;

                  &::before {
                    top: .1rem;
                    width: 2rem;
                    height: 2rem;
                    border: 0.5px solid rgba(255, 255, 255, 0.5);
                  }

                  &-link {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
#name-error,
#pass-error,
#login-error,
#pass-reg-error,
#pass-repeat-reg-error,
#email-reg-error {
  position: absolute;
  top: 1.8rem;
  left: 28rem;
  background: #FFBC25;
  box-shadow: 1px 1px 4px rgba(255, 255, 255, 0.25);
  border-radius: 1px;
  color: #181818;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 1rem .8rem;
  width: max-content;

  &::before {
    position: absolute;
    content: "";
    width: 1rem;
    height: 1rem;
    top: 1.4rem;
    left: -.5rem;
    background: #FFBC25;
    border-radius: 0px;
    transform: matrix(0.71, -0.71, 0.7, 0.71, 0, 0);
  }
}

.modal {
  max-width: 100%;
  display: none;
  overflow: unset;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4.8rem;
    background: #101010;
    border-bottom: 1px solid #424242;
    padding: 1rem 3rem;
    text-align: center;
  }

  &__title {
    width: 100%;
    font-size: 24px;
    line-height: 28px;
    color: #DADADA;
    text-align: center;

    @media all and (max-width: 375px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__content {
    display: flex;
    padding: 1.5rem 1.5rem 2.2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    color: #DADADA;
    text-align: center;

    & p {
      margin-bottom: 1.6rem;
    }
  }

  .email-recovery {
    font-size: 18px;
    line-height: 24px;
  }

  .email-icon {
    margin-top: .8rem;
  }
}

.modal--leave {
  max-width: 35.3rem;

  @media all and (max-width: 576px) {
    max-width: 28rem;
  }

  .modal__header {
    padding: 1.4rem 2rem;

    @media all and (max-width: 576px) {
      padding: 1rem 1.6rem;
    }
  }

  .modal__title {
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: center;

    @media all and (max-width: 576px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  .modal__content {
    padding: 0;
  }

  .form__button-wrapper {
    display: flex;
  }

  .form--leave {
    width: 100%;
  }

  .form__button {
    display: inline-block;
    width: 50%;
    padding: 1rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-transform: uppercase;
    border: none;
    border-right: .1rem solid #3F3F3F;
    background: #FFBC25;
    cursor: pointer;

    @media all and (max-width: 576px) {
      padding: .8rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &:last-child {
      border: none;
    }
  }

  .fancybox-close-small {
    display: none;
  }
}

.modal--match-detail {
  width: 71.2rem;

  .match-chat {
    .modal__header {
      justify-content: center;
      padding: 1.5rem;
      background: transparent;
    }
    .modal__title {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: white;
      text-align: center;
    }
  }

  .modal__header {
    position: relative;
    border-bottom: none;
  }

  .modal__title {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: left;
  }

  .modal__content {
    padding: 1.6rem;
    background: #181818;
  }

  .modal__chat-link, .modal__detail-link {
    position: relative;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
    white-space: nowrap;
    color: #FFBC25;

    &:hover {
      color: #FFBC25;
    }
    &:focus {
      outline: none;
    }
  }

  .modal__chat-link {
    padding-right: 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background: url("../img/bx-chat.svg") center center/auto no-repeat;
    }
  }

  .modal__detail-link {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding-left: 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 40%;
      left: .8rem;
      width: .8rem;
      height: .8rem;
      border: 2px solid #ffbc25;
      border-right: transparent;
      border-bottom: transparent;
      transform: rotate(-45deg) translateY(-50%);
    }
  }

  .match-info {
    position: relative;
    margin-bottom: 5.2rem;
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    column-gap: 7.6rem;

    &__score {
      position: absolute;
      top: 15rem;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #FFFFFF;
      letter-spacing: -0.04em;
    }

    &__team-detail {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.team--winner {
        .match-info__mark-winner {
          display:  flex;
        }
      }

      &.team--unknown {
        .match-info__team-logo {
          background: rgba(32, 32, 32, 0.8);

          &::before {
            display: inline-block;
          }
        }
        .match-info__team-logo-img {
          display: none;
        }
      }
    }

    &__team-logo {
      position: relative;
      width: 30rem;
      height: 30rem;

      &::before {
        content: '?';
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        font-size: 10rem;
        line-height: 1;
        color: white;
      }
    }

    &__team-logo-img {
      vertical-align: top;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__team-name {
      margin-top: 1.1rem;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #FFBC25;
    }

    &__team-list {
      width: 100%;
      margin-top: 1.6rem;
      display: flex;
      justify-content: space-between;
    }

    &__team-member {
      width: 5.2rem;
      border-radius: .3rem;

      &-photo {
        vertical-align: top;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: .3rem;
      }
    }

    &__choose-winner {
      height: 4.2rem;
      padding: 1rem 3rem;
      margin-top: 3.2rem;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.1rem;
      box-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.5);
      background: #FFBC25;
      border-radius: 10rem;
      border: none;
      cursor: pointer;
    }

    &__mark-winner {
      display: none;
      margin-top: 3rem;
      justify-content: center;

      &-text {
        position: relative;
        padding-right: 2rem;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.1rem;
        color: #FFBC25;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          background: url("../img/trophy.svg") center center/auto no-repeat;
        }
      }
    }
  }
}

.modal--settings, .modal--team-settings, .modal--create-team {
  width: 94.5rem;
  background: #1F1F1F;
  box-shadow: 4px 4px 4px rgba(9, 7, 7, 0.5);

  .modal__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
  }
  .modal__content {
    text-align: unset;

    @media all and (max-width: 576px) {
      padding-bottom: 4rem;
    }
  }

  .fancybox-close-small {
    svg path {
      fill: #F3B220;
    }
  }
}

.modal--subscribe-payment {
  width: 100%;
  max-width: 33.8rem;

  .modal__header {
    background: #1F1F1F;
    border: none;
    padding: 1rem 1.6rem;
  }

  .modal__content {
    padding: 2.7rem 1.6rem 2rem;
    background: #121212;
  }

  .modal__title {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: left;
    color: white;
  }

  .fancybox-close-small {
    svg path {
      fill: #DCDCDC;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .form__button-wrapper {
    margin-top: 4.1rem;
  }

  .button.button--payment {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4.7rem;
    width: 100%;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    border: none;
    background: #FFBC25;
    border-radius: .2rem;

    &:hover {
      background: darken(#FFBC25, 5%);
    }
  }
}

.modal--payment-result {
  width: 100%;
  max-width: 87rem;

  .modal__content {
    display: flex;
    flex-direction: column;
    padding: 7rem 2rem 3.2rem;
    background: #121212;

    @media all and (max-width: 576px) {
      padding: 4.6rem 2rem 2rem;
    }

    &.success {
      .payment-text {
        color: #05C46B;
      }
      .payment-text.text--error, .payment-img.img--error {
        display: none;
      }
    }

    &.error {
      .payment-text {
        color: #EB5757;
      }
      .payment-text.text--success, .payment-img.img--success {
        display: none;
      }
    }
  }

  .payment-img {
    width: 8.3rem;
    height: 8.3rem;

    @media all and (max-width: 576px) {
      width: 6rem;
      height: 6rem;
    }
  }
  .payment-text {
    margin: 3.8rem 0 0 0;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;

    @media all and (max-width: 576px) {
      margin: 2.4rem 0 0 0;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }

  }
  .payment-home-link {
    margin-top: 6.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 4.7rem;
    max-width: 30rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #121212;
    background: #FFBC25;
    border-radius: .2rem;
  }

  .fancybox-close-small {
    display: none;
  }
}

.form--settings {
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  color: #DADADA;

  @media all and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media all and (max-width: 576px) {
    font-size: 14px;
    line-height: 16px;
  }

  .form__content {
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 576px) {
      flex-direction: column;
    }
  }

  .form__label-upload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    @media all and (max-width: 576px) {
      margin-left: 1rem;
    }
  }

  .form__nickname {
    display: none;
    font-size: 1.8rem;
    line-height: 2.1rem;

    @media all and (max-width: 576px) {
      display: inline-block;
    }
  }

  .form__profile-photo {
    position: relative;
    margin-right: 2rem;
    width: 22.3rem;
    height: 22.3rem;

    @media all and (max-width: 768px) {
      width: 18rem;
      height: 18rem;
    }
    @media all and (max-width: 640px) {
      margin-right: 1rem;
    }
    @media all and (max-width: 576px) {
      width: 100%;
      height: 12rem;
      display: flex;
      align-items: flex-end;
    }
    @media all and (max-width: 375px) {
      height: 8rem;
    }

    &.profile-photo--team {
      @media all and (max-width: 768px) {
        width: 18rem;
        height: 18rem;
      }
      @media all and (max-width: 576px) {
        margin: 0 auto;
        width: 29rem;
        height: 29rem;
      }

      .form__profile-photo-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .form__label-upload-wrapper {
        width: unset;
        height: unset;
        margin-left: 0;
      }

      .label--upload {
        @media all and (max-width: 576px) {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          width: 100%;
          height: 100%;
          margin-bottom: 0;
          align-items: center;
          justify-content: center;
          background: rgba(0,0,0,.6);
          cursor: pointer;

          &::before {
            display: none;
          }
        }
      }
    }

    &-img {
      border-radius: .2rem;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media all and (max-width: 576px) {
        width: 12rem;
        height: 12rem;
      }
      @media all and (max-width: 375px) {
        width: 8rem;
        height: 8rem;
      }
    }
  }

  .form__inputs-row {
    flex: 1;
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 576px) {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      flex-direction: column;
      border-top: 1px solid #3F3F3F;
    }
  }

  .form__inputs-col {
    padding-right: 5.4rem;
    flex: 1;
    border-right: 1px solid #3F3F3F;

    @media all and (max-width: 920px) {
      padding-right: 2rem;
    }
    @media all and (max-width: 640px) {
      padding-right: 1rem;
    }
    @media all and (max-width: 576px) {
      padding-right: 0;
      border: none;
    }

    &:last-child {
      padding-right: 2rem;
      padding-left: 5.4rem;
      border: none;

      @media all and (max-width: 920px) {
        padding-right: .5rem;
        padding-left: 2rem;
      }
      @media all and (max-width: 640px) {
        padding-left: 1rem;
      }
      @media all and (max-width: 576px) {
        padding-left: 0;
        border: none;
      }
    }
  }

  .form__inputs-col-title {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-left: 2.6rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #DADADA;

    &::before {
      content: '';
      position: absolute;
      top: .3rem;
      left: 0;
      width: 2.1rem;
      height: 1.6rem;
      display: block;
    }

    &.title--data {
      &::before {
        background: url("../img/title-data-icon.svg") center center/contain no-repeat;
      }
    }
    &.title--social {
      &::before {
        background: url("../img/title-social-icon.svg") center center/contain no-repeat;
      }
    }
  }

  .form__label {
    display: block;
    margin-bottom: .5rem;

    &.label--upload {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, .6);
      cursor: pointer;

      @media all and (max-width: 576px) {
        position: relative;
        top: unset;
        left: unset;
        width: max-content;
        height: 3rem;
        margin-top: auto;
        padding: .5rem 3.2rem .5rem 1rem;
        border-radius: .2rem;
        background: #121212;
      }

      &::before {
        content: '';
        display: none;
        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        background: url("../img/gear-avatar.svg") center center/contain no-repeat;

        @media all and (max-width: 576px) {
          display: block;
        }

      }

      &:hover {
        color: white;
      }
    }
  }

  .form__input-wrapper {
    position: relative;

    .form__input-field-button {
      position: absolute;
      top: .9rem;
      right: .9rem;
      display: block;
      width: 2rem;
      height: 2rem;
      font-size: 0;
      border: none;
      cursor: pointer;
    }

    &.input-wrapper--reload {
      .form__input-field-button {
        background: transparent url("../img/reload.svg") center center/contain no-repeat;
      }
    }

  };

  .form__input {
    width: 100%;
    height: 3.5rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #DADADA;
    border: 1px solid rgba(218, 218, 218, 0.3);

    @media all and (max-width: 768px) {
      //height: 3rem;
      font-size: 15px;
      line-height: 18px;
    }
    @media all and (max-width: 576px) {
      font-size: 14px;
      line-height: 16px;
    }
    @media all and (max-width: 375px) {
      margin-bottom: 1.5rem;
    }

    &:disabled {
      color: rgba(218, 218, 218, 0.5);
      background: rgba(94, 94, 94, 0.5);
    }

    &.input--reload {
      padding-right: 3.5rem;
    }
  }

  .form__input-file {
    display: none;
  }

  .form__desc {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
  }

  .form__input-desc {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: rgba(255, 255, 255, 0.5);
  }

  .form__button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.5rem;

    @media all and (max-width: 576px) {
      margin-top: .5rem;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    &.wrapper--space {
      @media all and (max-width: 576px) {
        flex-direction: row;
      }

      .form__button.button--cancel {

        @media (max-width: 576px) {
          margin-top: 0;
          margin-right: 2.5rem;
        }
      }
    }
  }



  .form__button {
    padding-top: .3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    font-size: 14px;
    line-height: 16px;
    color: #1F1F1F;
    border: none;
    border-radius: .2rem;
    background: #FFBC25;
    cursor: pointer;

    &:hover {
      background: #fbbf37;
    }

    &:focus {
      outline: none;
    }

    &.button--cancel {
      color: #DADADA;
      margin-right: 2.5rem;
      background: transparent;

      @media all and (max-width: 576px) {
        margin-right: 0;
        margin-top: 1.6rem;
      }

      &:hover {
        background: transparent;
      }
    }

    &.button--delete {
      margin-right: auto;
      padding: .7rem 2.5rem;
      color: #FFBC25;
      background: transparent;
      border: .2rem solid #FFBC25;

      @media all and (max-width: 576px) {
        display: none;
      }
    }
  }

  .nice-select {
    width: 100%;
    margin-bottom: .8rem;
    font-weight: 500;
    font-size: 1.6rem;
    color: rgba(218, 218, 218, 0.5);
    border-radius: .6rem;
    background: #121212;

    &::after {
      width: .4rem;
      height: .4rem;
      border-color: rgba(218, 218, 218, 0.5);
    }

    .list {
      margin-top: 0;
      width: 100%;
      font-size: 1.6rem;
    }
  }
}

.modal--create-team {
  width: 100%;
  max-width: 40rem;

  .form--create-team {
    display: flex;
    flex-direction: column;
  }

  .modal__content {
    @media all and (max-width: 576px) {
      padding-bottom: 2.2rem;
    }
  }
}

.modal--auth {
  .fancybox-close-small {
    display: none;
  }
}

.modal--reg-email, .modal--recovery-email {
  background: #181818;
  min-width: 48rem;

  @media all and (max-width: 480px) {
    min-width: unset;
  }
}

.check-mail {
  width: 48rem;
  background: $form-center;
  border-radius: 3px;

  &__header {
    border-bottom: 2px solid rgba(206, 206, 206, 0.3);
    text-align: center;
    position: relative;

    &-text {
      display: block;
      padding: 1rem 0;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #DADADA;
    }
  }

  &__info {
    padding-top: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 2.2rem;

    &-email {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #DADADA;
      padding-bottom: .8rem;
    }
  }

}

.fancybox-content {
  padding: 0 !important;
}

.fancybox-slide {
  padding: 0;
}

.fancybox-slide--html .fancybox-close-small {

  path {
    fill: #DCDCDC;
  }
}

.modal--create-team {

}

.modal-stream {
  width: 100%;
  max-width: 800px;
  height: 450px;
}

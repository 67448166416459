.back-tournaments {
  margin-top: 5rem;
}

.tournament-detail-header {
  font-size: 3.2rem;
  line-height: 3.7rem;
  color: #FFBC25;
  margin: 1.8rem 0;
}

.tournament-tabs {
  display: flex;
  margin-bottom: 1rem;

  &__item {
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #DADADA;
    margin-right: 3rem;
    cursor: pointer;

    &.tab-item--active {
      color: #FFBC25;
      border-bottom: .3rem solid #FFBC25;
      padding-bottom: 1rem;
    }
  }
}

.tournament-sections-wrapper {
  display: flex;
  justify-content: space-between;

  &__button-group {
    margin-bottom: 1.2rem
  }

  & .tournament-tab-content {
    flex-grow: 1;

    &__block {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }

    &__block.section--active {
      display: block;
      width: 74%;
      height: unset;
      opacity: 1;
      min-width: 848px;
      background: #121212;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
      border-radius: .3rem;
      margin-bottom: 7.4rem;
    }

    &__block.section--grid {
      background: #1F1F1F;
    }
  }

  & .tournament-aside {
    width: calc(25% - 3rem);
    margin-left: 3rem;
    margin-bottom: 7.4rem;
    flex-shrink: 0;
  }
}

// aside sections
.tournament-aside {
  &__admin, &__chat, &__links {
    background: #121212;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: .3rem;
    padding: 1.6rem;
    margin-bottom: 2rem;
  }

  &__admin--header, &__chat--header, &__links--header {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #FFFFFF;
    margin-bottom: 2.5rem;
  }

  &__admin--info {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;

    & .admin-ava {
      width: 4rem;
      height: 4rem;
      margin-right: 1rem;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    & .admin-name {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: #DADADA;
    }
  }

  &__chat--content {
    & .chat-list {
      overflow-y: scroll;
      height: 24.8rem;
      padding-right: .8rem;

      &__item {
        margin-bottom: .8rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.6rem;

        & .heading {
          display: flex;
          margin-bottom: .4rem;
          width: 100%;

          & .name {
            color: #FFBC25;
            margin-right: auto;
          }

          & .time {
            color: #828282;
          }
        }

        .message-text {
          color: #FFFFFF;
          font-weight: normal;
        }

        .message-delete {
          cursor: pointer;
          background: none;
          border: none;
          outline: none;
          padding: none;
          margin-right: 10px;
        }
      }
    }

    & .message-form {
      font-weight: 500;

      &__window {
        background: #1C1C1C;
        border-radius: .6rem;
        border: none;
        outline: none;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #FFFFFF;
        resize: none;
        height: 6.6rem;
        margin: 1rem auto .6rem;
        padding: .8rem;
        width: 100%;
      }

      &__sending {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--button {
          color: #FFBC25;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.4rem;
          background: none;
          border: none;
          cursor: pointer;

          &:hover {
            color: #F3B220;
          }

          &:focus {
            outline: none;
          }
        }

        &--arrow {
          width: 0;
          height: 0;
          border-top: .6rem solid transparent;
          border-left: 1.2rem solid #ffbc25;
          border-bottom: .6rem solid transparent;
          cursor: pointer;

          &:hover {
            border-left-color: #F3B220;
          }
        }
      }
    }
  }

  &__links--list {
    display: flex;
    flex-direction: column;
    margin-bottom: -2rem;

    & li {
      margin-bottom: 2rem;

      & a {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 4rem;
        color: #DADADA;

        & .icon-wrapper {
          display: inline-block;
          width: 4rem;
          height: 4rem;
          border-radius: .3rem;
          margin-right: 1rem;

          &.discord {
            background: url('./../img/discord.svg') center no-repeat, #1F1F1F;
          }

          &.vk {
            background: url('./../img/vk.svg') center no-repeat, #1F1F1F;
          }
        }
      }
    }
  }

  &__registration {
    & button {
      display: inline-block;
      text-align: center;
      width: 100%;
      height: 4.2rem;
      line-height: 4.2rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      border-radius: 10rem;
      font-weight: 500;
      font-size: 1.8rem;
      background: #FFBC25;
      border: none;
      color: #121212;

      &:hover {
        cursor: pointer;
        background: #F3B220;
      }

      &.disabled {
        background: #B8B8B8;
        color: #4A4A4A;

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    & p.error-text {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.5rem;
      text-align: center;
      color: #EB5757;
      margin: .9rem 0 1.2rem;

      &.hidden {
        display: none;
      }
    }
  }

  &__button-group {
    display: flex;
    justify-content: space-between;
    margin-left: -.5rem;
    margin-right: -.5rem;

    & .accept, & .decline {
      height: 4.2rem;
      line-height: 4.2rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      border-radius: 10rem;
      font-weight: 500;
      font-size: 1.4rem;

      &:hover {
        cursor: pointer;
      }
    }

    & .accept {
      background: #FFBC25;
      color: #121212;
      border: none;

      &:hover {
        background: #F3B220;
      }
    }

    & .decline {
      background: transparent;
      color: #828282;
      border: 1px solid #828282;

      &:hover {
        background: #2E2E2E;
      }
    }
  }
}

// info
.tournament-info {
  padding: 1.6rem 3.2rem .8rem;
  font-weight: 500;

  &__header {
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #FFFFFF;
    margin-bottom: 1.6rem;
  }

  &__container {
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: #DADADA;
    padding-right: .8rem;

    & p {
      margin-bottom: 2rem;
    }
  }
}

//teams
.tournament-teams {
  min-height: 52.1rem;
  padding: 1.6rem;

  &__header {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #FFFFFF;
    margin-bottom: 1.8rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;

    &--item {
      width: calc(33.33333% - 2rem);
      margin: 0 1rem 1.6rem;
      padding: 1rem;
      background: #1F1F1F;
      border: .5px solid rgba(220, 220, 220, 0.1);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: .3rem;

      & .ava-wrapper {
        width: 4rem;
        height: 4rem;
        float: left;
        margin-right: 8px;

        & img {
          max-height: 100%;
          max-width: 100%;
          border-radius: 3px;
        }
      }

      & .name {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #FFBC25;
        display: inline-flex;
        align-items: center;

        .sign {
          display: inline-block;
          margin-left: .4rem;

          &.success {
            width: 10px;
            height: 7.72px;
            background: url('../img/bx-check.svg') center no-repeat;
          }

          &.waiting {
            width: 8px;
            height: 8px;
            background: url('../img/carbon_time.svg') center no-repeat;
          }
        }
      }

      & .coming-date-time {
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: rgba(220, 220, 220, 0.3);
        margin-top: .8rem;
      }
    }
  }
}

// rules
.tournament-rules {
  padding: 1.6rem;
  font-size: 1.6rem;
  line-height: 1.9rem;

  &__header {
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 2.6rem;
  }

  &__container {
    font-weight: normal;
    color: #DADADA;
    overflow-y: auto;
    height: 100%;

    & p {
      margin-bottom: 2rem;
    }

    & ul {
      list-style-type: decimal;

      & li {
        margin: 1.8rem;
      }

      & li:last-child {
        margin-left: 2.4rem;
      }
    }
  }
}

.button-group {
  &__form {
    width: calc(50% - 1rem);
    margin: 0 .5rem;
  }

  &__button {
    width: 100%;
  }
}

// grid

.tournament-grid {

  &__slider {
    max-width: 72.8rem;
    margin: 0 auto;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #dadada;

    .slick-slide {
      &:first-child {
        .tournament-slide__teams-pair {
          &::before {
            display: none;
          }
        }
      }

      &:last-child {
        .tournament-slide__teams-pair {
          &::after {
            display: none;
          }
        }
      }
    }

    .tournament-slide {

      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.6rem;
        border-right: 1px solid #FFBC25;
        background: #121212;
      }

      &__teams-list {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        padding: 4rem 1.5rem;
      }

      &__teams-pair {
        position: relative;
        margin-bottom: .6rem;
        box-shadow: .2rem .4rem .4rem rgba(0, 0, 0, 0.5);
        cursor: pointer;

        &.pair--winner {
          &::before, &::after {
            border-bottom-color: #FFBC25;
          }

          .tournament-slide__pair-line {
            border-right-color: #FFBC25;
          }
        }

        &.pair--empty {
          height: 6rem;
          box-shadow: none;

          &::before, &::after {
            display: none;
          }
        }

        &.pair--single {
          &::before {
            display: none;
          }
        }

        &:nth-of-type(odd){
          .tournament-slide__pair-line {
            top: 50%;
          }
        }

        &:nth-of-type(even) {
          .tournament-slide__pair-line {
            bottom: 50%;
          }
        }

        &::before, &::after {
          content: '';
          position: absolute;
          top: 50%;
          display: block;
          width: 1.5rem;
          height: 0;
          border-bottom: .1rem solid #dadada;
        }

        &::before {
          left: -1.5rem
        }

        &::after {
          right: -1.5rem;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

      }

      &__team {
        position: relative;
        display: flex;
        align-items: center;
        height: 3rem;
        padding: 0 .8rem;
        border: 1px solid rgba(220, 220, 220, 0.3);
        background: #121212;

        &:first-child {
          border-radius: .3rem .3rem 0 0;
          border-bottom: none;
        }

        &:last-child {
          border-radius: 0 0 .3rem .3rem;
        }

        &.team--winner {
          background: #292929;
        }

        &-name {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: .8rem;
        }

        &-logo {
          margin-right: .8rem;
          width: 1.4rem;
          height: 1.4rem;
          vertical-align: top;
          object-fit: contain;
        }

        &-score {
          margin-left: auto;
        }
      }

      &__pair-line {
        position: absolute;
        right: -1.5rem;
        display: block;
        width: 0;
        border-right: .1rem solid #dadada;
      }
    }

    .slick-prev, .slick-next {
      top: 0;
      width: 6rem;
      height: 3.6rem;
      background: #FFBC25;
      transform: translate(0, 0);

      &::before {
        content: '';
        opacity: 1;
        display: block;
        position: absolute;
        left: 30%;
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid #3C3C3C;
        border-left: transparent;
        border-bottom: transparent;
        transform: rotate(45deg) translateY(-50%);
        box-sizing: border-box;
      }

      &.slick-disabled {
        background: transparent;
      }
    }

    .slick-prev {
      left: -6rem;

      &::before {
        left: 40%;
        transform: rotate(45deg) translateY(-50%) scale(-1, -1);
      }
    }

    .slick-next {
      right: -6rem;
    }
  }

}

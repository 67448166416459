@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-container {
  max-width: 118rem;
  padding: 0 2rem;
  width: 100%;
  margin: 3rem auto;
  display: grid;
  grid-template: auto / 26.3rem 1fr;
  gap: 2rem;

  @media all and (max-width: 992px) {
    gap: 1rem;
    grid-template: auto / 22rem 1fr;
  }
  @media all and (max-width: 768px) {
    grid-template: auto / 1fr;
  }
  @media all and (max-width: 576px) {
    margin-top: 2rem;
  }
  @media all and (max-width: 480px) {
    padding: 0;
    gap: 0;
  }


  .aside {
    border-radius: .3rem;

    @media all and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 1rem;
      background: #121212;
      box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
    }
    @media all and (max-width: 480px) {
      padding: 1.2rem 1.6rem;
    }

    &-profile {
      padding: 1rem 2rem;
      border-radius: 2px;
      background: #121212;
      box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);

      @media all and (max-width: 992px) {
        padding: 1rem;
      }
      @media all and (max-width: 768px) {
        display: flex;
        padding: 0;
        box-shadow: none;
      }

      & + & {
        margin-top: .8rem;
      }

      &-nickname {
        line-height: 21px;
        font-size: 1.8rem;
        font-weight: 500;
        color: #DADADA;
        @include truncate;

        @media all and (max-width: 768px) {
          order: 2;
        }
      }

      &-name {
        @include truncate;
        color: #DADADA;
        font-size: 1.4rem;
        line-height: 16px;
        font-weight: 300;
        margin-top: .5rem;
      }

      &-avatar {
        margin-top: 1rem;
        width: 100%;
        height: 22.3rem;

        @media all and (max-width: 992px) {
          height: 18rem;
        }

        @media all and (max-width: 768px) {
          order: 1;
          margin-top: 0;
          margin-right: 2rem;
          width: 18rem;
        }
        @media all and (max-width: 576px) {
          width: 12rem;
          height: 12rem;

        }
        @media all and (max-width: 375px) {
          margin-right: 1rem;
          width: 8rem;
          height: 8rem;
        }

        &-image {
          width: 100%;
          height: 100%;
          border-radius: 3px;
          object-fit: cover;
        }
      }

      &-status {
        padding: 1rem 0;
        color: #DADADA;
        line-height: 14px;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .aside-profile-status-indicator {
          width: 1rem;
          height: 1rem;
          display: block;
          border-radius: 50%;
          margin-left: .5rem;
        }
      }

      &-status__online {
        .aside-profile-status-indicator {
          background: #85BE28;
        }
      }

      &-status__offline {
        .aside-profile-status-indicator {
          background: #EC203D;
        }
      }
    }

    &-settings-button-wrapper, .aside-join-button-wrapper {
      margin-top: 1rem;
      height: 5.1rem;
      box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
      border-radius: 3px;
      background: #121212;

      @media all and (max-width: 768px) {
        background: rgba(88, 88, 88, 0.6);
        height: 3.6rem;
        box-shadow: none;
      }
      @media all and (max-width: 375px) {
        height: 3rem;
        width: 3rem;
      }

      &-url {
        height: 100%;
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
          margin-left: .75rem;
          color: #DADADA;
          line-height: 21px;
          font-size: 1.8rem;
          @media all and (max-width: 576px) {
            margin-left: 0;
            font-size: 0;
          }
        }
      }
    }

    &-socials {
      background: #121212;
      box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
      border-radius: 2px;
      padding: 1rem 2rem 0 2rem;
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      @media all and (max-width: 576px) {
        padding: 0;
      }

      &-unit {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 4.1rem;
        margin-bottom: 1rem;

        &-image {
          margin-right: 1.5rem;
        }

        &-info-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media all and (max-width: 576px) {
            display: none;
          }

          &-title {
            color: rgba(218, 218, 218, 0.5);
            line-height: 16px;
            font-size: 1.4rem;
            font-weight: 300;
          }

          &-sociallogin {
            color: #DADADA;
            line-height: 21px;
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  .profile-content {
    background: #121212;
    box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
    border-radius: 2px;
    display: flex;
    flex-direction: column;

    .tabs {

      &__panel {
        background: #1F1F1F;
        border-bottom: 1px solid #3F3F3F;
        padding: 0 3rem;
        display: flex;

        @media all and (max-width: 992px) {
          padding: 0 2rem;
        }
      }

      &__panel-item {
        display: inline-block;
        padding: 1rem 0;
        margin-right: 1.6rem;
        height: 4.1rem;
        background: transparent;
        border: none;
        border-radius: 0;
        font-size: 1.8rem;
        line-height: 2.1rem;
        color: #DADADA;
        border-bottom: .2rem solid transparent;
        box-sizing: border-box;
        cursor: pointer;

        @media all and (max-width: 576px) {
          font-size: 1.6rem;
          line-height: 2rem;
        }

        &:hover {
          color: #FFBC25;
        }

        &--active {
          color: #FFBC25;
          border-bottom-color: #FFBC25;
        }
      }

      &__item {
        display: none;

        &--active {
          display: block;
        }
      }
    }

    &__inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 29.2rem;
      padding: 2rem 3rem;

      @media all and (max-width: 992px) {
        min-height: 25rem;
        padding: 2rem;
      }

      &.inner--empty {
        align-items: center;
        justify-content: center;
      }
    }

    &__message {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #858585;
      text-align: center;

      @media all and (max-width: 992px) {
        font-size: 2rem;
        line-height: 2.4rem;
      }

      @media all and (max-width: 576px) {
        font-size: 1.8rem;
      }
    }

    &__button-wrapper {
      padding-top: 2rem;
      margin-top: auto;
      align-items: center;

      @media all and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__button {
      padding: .8rem 2.6rem .6rem;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #121212;
      border: none;
      border-radius: .2rem;
      background: #FFBC25;

    }

    &__button-info {
      display: inline-block;
      margin-right: 1.6rem;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #5E5E5E;

      @media all and (max-width: 576px) {
        margin-bottom: 1rem;
      }
    }


    &-tabs-content {

      flex-grow: 1;

      .profile-content-tabs-teams {
        display: flex;
        flex-direction: column;

        &-unit {
          background: #1F1F1F;
          box-shadow: 2px 2px 4px rgba(9, 7, 7, 0.5);
          border-radius: 2px;
          margin-bottom: 2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__flex-start, &__flex-end {
            display: flex;
            align-items: center;
          }

          &-avatar {
            width: 5.4rem;
            height: 5.4rem;

            &-img {
              width: 100%;
              height: 100%;
            }
          }

          &-infofield {
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 28px;
            color: #DADADA;
            margin-left: 1.5rem;
            flex-shrink: 0;
          }

          &-infofield:after {
            content: ' ';
            height: 2.8rem;
            align-self: center;
            margin-left: 1.5rem;
            border: 0.5px solid #3F3F3F;
          }

          &-infofield__teamname {
            color: #FFBC25;
          }

          &-team-members {
            font-size: 2.4rem;
            line-height: 28px;
            color: #DADADA;
            padding-left: 1.5rem;

            .team-members-icon {
              margin-left: 0.6rem;
            }
          }

          &-exitteam {
            background: #FFBC25;
            border-radius: 2px 2px 2px 2px;
            display: block;
            display: flex;
            align-items: center;
            height: 5.4rem;

            &-logo-game {
              width: 5.4rem;
              height: 5.4rem;
              background-size: cover;
              border: 2px;
            }

            &-text {
              border-left: 1px solid #3F3F3F;
              color: #1F1F1F;
              padding: 0 1rem;
              display: flex;
              font-size: 1.8rem;
              font-weight: 500;
              height: 100%;
              justify-content: center;
              align-items: center;
              // display: block;
            }

            .logo-game-linage {
              background-image: url('../img/linage.svg')
            }
          }

        }

        &-create-team {
          background: #FFBC25;
          border-radius: 2px;
          width: 16.6rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          font-size: 1.4rem;
          transition: 300ms;
          color: #121212;
        }

        &-create-team:hover {
          transition: 300ms;
          opacity: 0.9;
        }
      }
    }
  }
}

.aside-subscription { 
  background: #121212;
  box-shadow: 2px 2px 4px rgba(9, 7, 7, .5);
  border-radius: 2px;
  padding: 1.5rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  color: #ffbc25;
  line-height: 16px;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}

.tournaments-list {
  display: block;

  &__inner {
    display: block;
  }

  &__row {
    margin-bottom: 20px;
  }
}

.p-tournament {
  display: flex;
  background: #1F1F1F;
  color: #DADADA;
  font: 18px/21px "Roboto", sans-serif;

  &__mobile-icon {
    display: none;
  }

  &__title-cell,
  &__date-cell,
  &__participants-cell,
  &__result-cell {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__title-cell,
  &__date-cell,
  &__participants-cell {
    margin-top: 15px;
    margin-bottom: 15px;
    border-right: .5px solid #3f3f3f;
  }

  &__icon-cell {
    flex-shrink: 0;
    width: 54px;
    height: 54px;
    align-self: center;
  }

  &__title-cell {
    color: #FFBC25;
    text-align: start;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
  }

  &__date-cell {
    width: 100px;
  }

  &__participants-cell {
    width: 110px;
  }

  &__participants:after {
    content: '';
    display: inline-block;
    width: 21px;
    height: 17.5px;
    margin-left: 10px;
    background: url(../img/participants.svg) no-repeat;
  }

  &__result-cell {
    width: 120px;
  }

  &__status-cell {
    flex-shrink: 0;
    display: flex;
  }

  &__status {
    width: 115px;
    color: #1F1F1F;
    background: #FFBC25;
    display: flex;
    align-items: center;
    justify-content: center;

    &--finished {
      background: rgba(255, 188, 37, .6);
    }
  }

  @media (max-width: 1050px) {
    flex-wrap: wrap;
    justify-content: center;

    &__icon-cell {
      display: none;
    }

    &__mobile-icon {
      display: inline-block;
      margin-right: 10px;
      flex-shrink: 0;
      width: 27px;
      height: 27px;
    }

    &__title-cell {
      width: 100%;
      border-right: none;
    }

    &__date-cell {
      width: 33.3333%;
    }

    &__participants-cell {
      width: 33.3333%;
    }

    &__result-cell {
      width: 33.3333%;
    }

    &__status {
      margin-bottom: 10px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}

.aside-socials-unit-image-element {
  width: 34px;
  height: 38px;
}

@import "fonts";

.wrapper {
  max-width: 114rem;
  margin: 0 auto;
  width: 100%;
}

.nav-text {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $t-gray;

  display: inline-block;
  margin-right: 2.2rem;

  &:last-of-type {
    margin-right: 0;
  }

  @media all and (max-width: 992px) {
    font-size: 16px;
  }

  &:hover {
    color: $t-yellow;
  }

  &--active {
    color: $t-yellow;
    font-weight: 500;
  }

  &--header-active {
    color: $t-yellow;
    font-weight: bold;

  }

  &--sign-up {
    color: #121212;
    background: #ffbc25;
    padding: .5rem 1.2rem;
    border-radius: 0.2rem;

    @media all and (max-width: 430px) {
      display: none;
    }

    &:hover {
      color: #121212;
    }
  }
}

.drop-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: $t-gray;

  &:hover {
    color: $t-yellow;

    svg path {
      fill: #F3B220;
    }
  }

  &--formtext {
    font-size: 14px;
    line-height: 16px;
  }
}

.hide {
  display: none;
}

.show {
  display: flex !important;
}

.non-border {
  border-bottom: 2px solid #FFBC25;
}

.header-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: $t-gray;

  @media all and (max-width: 992px) {
    font-size: 30px;
    line-height: 38px;
  }
  @media all and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
  @media all and (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.headline-news {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: $t-gray;

  @media all and (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media all and (max-width: 576px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media all and (max-width: 480px) {
    order: 2;
  }

  @media all and (max-width: 375px) {
    font-size: 14px;
    line-height: 18px;
  }

  &--show-more {
    font-weight: bold;
    color: $footer;
  }
}

.description-news {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $news-description;

  @media all and (max-width: 992px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  @media all and (max-width: 576px) {
    height: 2.3rem;
    white-space: nowrap;
    width: 26rem;
  	overflow: hidden;
    text-overflow: ellipsis;
  }

  &--leftside {
    color: $t-gray;
  }

  &--author {
    color: $t-yellow;
  }
}

.footer-news {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $news-description;
  transition: .2s;

  @media all and (max-width: 992px) {
    font-size: 12px;
    line-height: 14px;
  }

  &:hover {
    color: white;
  }
}

.text-comment {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: rgba(218, 218, 218, 0.5);

  &--left-description {
    font-weight: 400;
    color: $t-gray;
  }
}

.filter-headline {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #3C3C3C;

  @media all and (max-width: 576px) {
    font-size: 14px;
    line-height: 16px;
  }

  &--news-back {
    color: $t-gray;
    font-weight: normal;
  }
}

.rounding-pic {
  border-radius: 3px 3px 0px 0px;
}

.other-thematic-news {
  width: 28rem;
  margin-left: 2rem;

  @media all and (max-width: 992px) {
    display: none;
  }

  .news-content {
    display: flex;
    flex-direction: column;

    &__filter-name {

      background: #F3B220;
      /* тень */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px 3px 0px 0px;
      text-align: center;

      &-text {
        display: inline-block;
        padding: .8rem 0 .7rem;
      }
    }

    &__item {
      background: #1F1F1F;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      margin-bottom: 1.5rem;

      &-info {
        padding: 1rem;

        &-headline {
        }

        &-description {
          display: inline-block;
          padding-top: .5rem;
        }
      }
    }

    &__item-prew-pic {
      max-width: 100%;
    }
  }
}

.news-article {
  display: flex;

  &-palka {
    width: 1rem;
    background: $t-yellow;
  }

  &-text {
    padding-left: 1.3rem;
  }
}

.rating {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: $green-rating;

  &--negative {
    color: $red-rating;
  }
}

.plus {
  display: block;
  width: .7rem;
  height: .7rem;
  border: 1px solid $green-rating;
  border-right: none;
  border-bottom: none;
  -webkit-transform: rotate(45deg);

  &--minus {
    border: 1px solid $red-rating;
    -webkit-transform: rotate(45deg);
    border-left: none;
    border-top: none;
  }
}

.comments-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $t-gray;

  &--status {
    font-size: 12px;
    line-height: 14px;
  }
}

.second-answer {
  margin-left: 4rem;
}

.third-answer {
  margin-left: 9rem;
}

.head-settings {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $t-gray;

  &--personal {
    font-size: 18px;
    line-height: 21px;
  }
}

.active-link {
  color: $t-yellow;

  svg path {
    fill: #F3B220;
  }
}

.point-full-width {
  max-width: calc(50% - .75rem);
  grid-column-start: span 2;

  @media all and (max-width: 900px) {
    max-width: unset;
    grid-column-start: unset;
  }
}

.input-point {
  background: red;
}

.button {
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &--submit {
    display: block;
    width: 100%;
    height: 4.2rem;
    line-height: 4.2rem;
    text-align: center;
    background: #F3B220;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    font: 500 18px Roboto, sans-serif;
    color: #121212;

    &:disabled {
      color: gray;
    }
  }

  &--recovery {
    max-width: 16.3rem;
    font-size: 14px;
    line-height: 18px;
  }
}

.link {
  color: inherit;
  text-decoration: underline;
}

.form {

  &__group {
    margin-bottom: .5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__check-group {
    margin-top: 1rem;
  }

  &__submit-btn {
    margin-top: 1rem;
  }

  &__all-errors {
    margin-bottom: 1rem;
    color: white;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: .8rem;
    border: 1px solid #c40b0b;
  }

  &--hidden {
    display: none;
  }

  &__title {
    display: inline-block;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #DADADA;
  }

  &.recovery {
    .input-text {
      height: unset;
      margin-top: 1rem;
      padding: .9rem 1rem;
    }
    .form-group__label {
      font-weight: 300;
    }
  }
}


.form-group {

  &__label {
    display: block;
    cursor: pointer;
    font: 14px 'Roboto', sans-serif;
    margin-bottom: .5rem;
    color: #DADADA;
  }

  &__field {
    display: block;
    width: 100%;
  }
}

.input-text {
    height: 30px;
    line-height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    color: #DADADA;
    background: #1F1F1F;
    padding-left: .7rem;
    padding-right: .7rem;
    font: 14px 'Roboto', sans-serif;

  &:focus {
    outline: none;
  }
}

.checkbox-group {

  &__input {
    display: none;

    &:checked + .checkbox-group__label:before {
      background: url("../img/check.svg") no-repeat center center, #FFB946;
    }
  }

  &__label {
    display: block;
    position: relative;
    font: 13px "Roboto", sans-serif;
    color: #DADADA;
    padding-left: 3rem;

    &:before {
      cursor: pointer;
      position: absolute;
      top: calc(50% - 1rem);
      left: 0;
      flex-shrink: 0;
      content: '';
      display: inline-block;
      width: 2rem;
      height: 2rem;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
      margin-right: 1rem;
      transition: background-color 0.2s ease;
    }
  }
}

.input-group {
  position: relative;

  .input-text.is-invalid {
    border: 1px solid #C40B0B;
  }

  &__error {
    display: flex;
    align-items: center;
    position: absolute;
    top: calc(50% - 1.8rem);
    left: calc(100% + 9px);
    width: fit-content;
    background: #FFBC25;
    max-width: 24.5rem;
    min-height: 3.6rem;
    padding: .4rem .8rem;
    font: 14px/2rem Roboto, sans-serif;
    color: #181818;

    @media all and (max-width: 1160px) {
      margin-top: 1rem;
      max-width: unset;
      width: 100%;
      min-height: unset;
      position: relative;
      top: unset;
      left: unset;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 1.2rem;
      left: -5px;
      transform: matrix(0.71, -0.71, 0.7, 0.71, 0, 0);
      background: #FFBC25;

      @media all and (max-width: 1160px) {
        top: -.5rem;
        left: 1.2rem;
      }
    }
  }
}

.page-404 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__text {
    font-size: 160px;
    font-weight: 400;
    text-transform: uppercase;
    color: #FEFEFE;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 40px;

    @media (max-width: 1000px) {
      font-size: 100px;
      margin-bottom: 30px;
    }

    @media (max-width: 600px) {
      font-size: 60px;
      margin-bottom: 20px;
    }
  }

  &__404 {
    color: #F8B825;
  }

  &__btn-back {
    background: #D9D9D9;
    padding: 20px 24px;
    font-size: 36px;
    line-height: 36px;
    text-transform: uppercase;
    color: #000;
    display: flex;
    align-items: center;

    @media (max-width: 1000px) {
      font-size: 24px;
      margin-bottom: 20px;
      padding: 15px 16px;
    }

    @media (max-width: 600px) {
      font-size: 16px;
      margin-bottom: 15px;
      padding: 10px 12px;
    }

    &::after {
      content: '';
      background-image: url("../img/back.svg");
      background-size: cover;
      width: 33.5px;
      height: 30px;
      margin-left: 15px;

      @media (max-width: 1000px) {
        width: 22.3px;
        height: 20px;
      }

      @media (max-width: 600px) {
        width: 14.8px;
        height: 13.3px;
      }
    }
  }

  &__logo {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    margin-top: 60px;

    @media (max-width: 1000px) {
      font-size: 16px;
      margin-top: 30px;
    }

    @media (max-width: 600px) {
      font-size: 12px;
      margin-top: 20px;
    }
  }

  &__4 {
    color: #F8B825;
  }
}

.tournaments-stub {
  margin-top: 5.1rem;

  img {
    max-width: 100%;
    height: auto;
  }
}

.tournaments-header {
  font-weight: normal;
  margin: 3.2rem 0 2.1rem;
}

.tournaments-nav {
  display: inline-flex;
  margin-bottom: 2.8rem;

  .tournaments-select {
    margin-right: 1.6rem;
    font-weight: 500;
  }

  .game-select, .game-select .option {
    min-width: 14.1rem;
  }

  .discipline-select, .discipline-select .option {
    min-width: 19.7rem;
  }
}

.tournaments-container {
  display: flex;
  flex-wrap: wrap;
  margin: -1.5rem;

  &__item {
    width: calc(50% - 3rem);
    margin: 1.5rem;
    border-radius: 3px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);

    .tournaments-card__main {
      display: block;
      background: #121212;
      height: 31.2rem;
      position: relative;
      border-radius: 3px 3px 0 0;

      & img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 3px 3px 0 0;
      }

      & div {
        width: 9rem;
        height: 3rem;
        background: #FFBC25;
        color: #1F1F1F;
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        position: absolute;
        top: 1.6rem;
        left: 1.6rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 3rem;
        text-align: center;
      }
    }

    .tournaments-card__footer {
      background: #121212;
      padding: 1.6rem;

      h2 {
        font-family: 'Roboto Slab', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 3.2rem;
        line-height: 3.5rem;
        margin-bottom: 2.5rem;
        color: #FFBC25;

        &:hover {
          color: #F3B220;
        }
      }

      .footer-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-content: space-between;
        margin-left: -1.5rem;
        margin-right: -1.5rem;

        &__item {
          width: calc(50% - 3rem);
          margin: 0 1.5rem 1.6rem 1.5rem;
          display: inline-flex;
          justify-content: space-between;
        }

        .name {
          font-style: normal;
          font-weight: 500;
          font-size: 2rem;
          line-height: 2.3rem;
          color: #FFBC25;
        }

        .property {
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: #DADADA;
        }
      }
    }
  }
}

.next-tournament {
  text-align: center;
  margin: 4.5rem auto;

  &__link {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #FFBC25;

    &:hover {
      color: #F3B220;
    }

    &::after {
      content: '';
      display: inline-block;
      width: .6rem;
      height: .6rem;
      transform: rotate(-45deg);
      border: 0.4rem solid #FFBC25;
      margin: .4rem 0 .2rem 1rem;
      border-top: none;
      border-left: none;

      &:hover {
        color: #F3B220;
      }
    }
  }
}

.command-list {
  width: 100%;

  &__count {
    display: inline-block;
    margin-bottom: 1.7rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #ffffff;

    @media all and (max-width: 500px) {
      padding-left: 1.8rem;
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

  }

  &__item + &__item {
    margin-top: 1.6rem;

    @media all and (max-width: 375px) {
      margin-top: .8rem;
    }
  }

  &.list--team {

    .command-list__item {
      margin-top: .8rem;

      &:first-child {
        margin-top: 0;
      }

      @media all and (max-width: 920px) {
        padding: 0 1.9rem 0 0;
        flex-direction: row;
        align-items: center;
      }

      &-name {
        @media all and (max-width: 375px) {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
      }

      &-info {
        width: unset;
        padding: 0;
        margin-top: 0;
      }
    }
  }

  &__item {
    min-height: 5.4rem;
    padding: 0 1.2rem 0 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #dadada;
    background: #1F1F1F;
    box-shadow: .4rem .4rem .4rem rgba(9, 7, 7, 0.5);

    @media all and (max-width: 920px) {
      padding: 1rem;
      flex-direction: column;
      align-items: flex-start;
    }

    &.item--captain {

      .command-list__item-info {
        display: none;
      }

      .command-list__item-name {
        padding-left: 1.8rem;
        &::before {
          display: block;
        }
      }
    }
  }

  &__name-wrapper {
    display: flex;
    align-items: center;
  }

  &__item-logo {
    margin-right: 1.6rem;
    vertical-align: top;
    width: 5.4rem;
    height: 5.4rem;
    object-fit: cover;
  }

  &__item-name {
    position: relative;
    padding-left: 0;
    color: #ffffff;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: none;
      width: 1rem;
      height: 1.1rem;
      background: url("../img/crown-icon.svg") center center/auto no-repeat;
    }
  }

  &__item-info {
    height: 2.8rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.6rem;

    @media all and (max-width: 920px) {
      width: 100%;
      padding-left: 5.4rem
    }
    @media all and (max-width: 480px) {
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-top: 1.6rem;
      padding: 0;
    }
  }

  &__item-game {
    display: inline-flex;
    align-items: center;
    padding: 0 1.6rem;
    color: #FFBC25;
    height: 100%;
    border-right: .1rem solid #3F3F3F;

    @media all and (max-width: 480px) {
      padding-left: 0;
    }

  }

  &__item-role {
    display: inline-flex;
    align-items: center;
    padding: 0 1.6rem;
    height: 100%;
    border-right: .1rem solid #3F3F3F;
  }

  &__item-team {
    position: relative;
    padding: 0 1.6rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      width: 1rem;
      height: 1.1rem;
      background: url("../img/clarity_users-icon.svg") center center/auto no-repeat;
    }
  }

  &__item-left {
    padding-left: 1.9rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #FFBC25;
    border: none;
    background: transparent;
    cursor: pointer;

    @media all and (max-width: 920px) {
      margin-left: auto;
    }

    @media all and (max-width: 480px) {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }

  &__item-delete, &__item-captain {
    position: relative;
    padding: 1.6rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #ffffff;
    background: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;

    @media all and (max-width: 920px) {
      display: block;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      font-size: 0;
      background: #FFBC25;
      border-radius: .2rem;

      & + & {
        margin-left: .8rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      width: 1.1rem;
      height: 1.1rem;
      background: url("../img/crown-black.svg") center center/contain no-repeat;

      @media all and (max-width: 920px) {
        display: block;
      }
    }

  }

  &__item-delete {
    color: #FFBC25;
    border-left: .1rem solid #3F3F3F;

    @media all and (max-width: 920px) {
      border: none;
    }

    &::before {
      width: 1.2rem;
      height: 1.2rem;
      background: url("../img/delete.svg") center center/contain no-repeat;
    }
  }
}


.match-chat {
  display: none;
  background: #121212;
  padding: 0 1.6rem 2.5rem;
}

.chat-room {
  padding: 1.6rem;

  &__messages-block {
    font-size: 1.4rem;
    line-height: 1.6rem;
    height: 43rem;
    color: #DADADA;
    overflow: auto;
  }

  &__message + &__message {
    margin-top: .9rem;
  }

  &__message {
    display: flex;

    &.message--admin {
      .chat-room__message-author {
        color: #F2C94C;
      }
    }

    &-time, &-author {
      display: block;
      margin-right: 1.4rem;
    }

    &-time {
      color: #4F4F4F;
    }

    &-author {
      flex: 0 0 7.5rem;white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #BDBDBD;
    }

    &-text {
      display: block;
    }

    &-img {
      width: 16rem;
      height: 10rem;
      object-fit: cover;
      border-radius: .6rem;
      border: .1rem solid rgba(255, 255, 255, 0.5);
      vertical-align: top;
    }

    &-img-link {
      display: inline-block;
      margin: 1rem 1rem 0 0;
    }
  }

  &__form-textarea {
    margin-top: 1.6rem;
    padding: 1.6rem;
    width: 100%;
    min-height: 6.6rem;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: white;
    border-radius: .6rem;
    border: none;
    background: #1C1C1C;
    resize: none;

    &:focus {
      outline: none;
    }
  }

  &__form-files-input {
    display: none;
  }

  &__form-control {
    margin-top: 1.6rem;
    display: flex;
    justify-content: space-between;
  }

  &__form-btn {
    display: block;
    position: relative;
    padding: 0;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #FFBC25;
    border: none;
    border-radius: 0;
    background: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: .1rem;
      display: block;
      height: 1.2rem;
    }

    &.btn--files {
      padding-left: 1.6rem;

      &::before {
        left: 0;
        width: 1rem;
        background: url("../img/file-icon.svg") center center/contain no-repeat;
      }
    }

    &.btn--submit {
      padding-right: 1.6rem;

      &::before {
        right: 0;
        width: 1.2rem;
        background: url("../img/send-icon.svg") center center/contain no-repeat;
      }
    }
  }

  &__form-files-list {
    margin-top: .7rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    color: rgba(255,255, 255, .5);
    counter-reset: file;
  }

  &__form-files-item {
    display: flex;
    align-items: center;
    position: relative;
    counter-increment: file;
    margin-bottom: .2rem;

    &::before {
      content: counter(file)'. ';
    }
  }

  &__form-files-del {
    position: relative;
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    margin-left: .7rem;
    cursor: pointer;

    &::before, &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      display: block;
      width: .8rem;
      height: .1rem;
      background: rgba(255, 255, 255, .5);
    }

    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }

}

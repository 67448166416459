.news-header {
  padding-top: 3.8rem;
  display: flex;
  align-items: center;

  @media all and (max-width: 768px) {
    padding-top: 1.6rem;
  }

  &__stick {
    width: 1rem;
    height: 4.2rem;
    background: $t-yellow;
  }

  &__text {
    padding-left: 1.1rem;
  }
}

.news__author {
  font-style: italic;
}

.content-news {

  .news-info {

    &__header {
      padding-top: 1rem;
      padding-left: 2.1rem;

      @media all and (max-width: 576px) {
        display: none;
      }

      &-tab {
        box-sizing: border-box;
        cursor: pointer;
        margin-right: 2rem;
        padding-bottom: 1rem;

        @media all and (max-width: 992px) {
          padding-bottom: .5rem;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    &__tabcontent {
      margin-top: 1rem;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      background: $background-news;
      padding-bottom: 3.2rem;

      @media all and (max-width: 480px) {
        background: #181818;
        box-shadow: none;
      }
      .news-container {
        padding: 2rem 2rem 0;
        width: 100%;
        max-width: 114.5rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        @media all and (max-width: 576px) {
          padding: 2rem 1rem 0;
        }
        @media all and (max-width: 375px) {
          padding: 2rem 0 0;
        }

        .main-news {

          &__list {
            width: 100%;
            max-width: 80.5rem;
            height: min-content;
            background: $news-item;
            border-radius: 1px;
            display: flex;
            margin-bottom: 1.5rem;

            @media all and (max-width: 480px) {
              background: #121212;
              box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
            }

            &:first-child {
              position: relative;
              @media all and (max-width: 480px) {
                min-height: 14.6rem;

                &::before {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 70%;
                  background: linear-gradient(180deg, rgba(137,137,137,0) 49%,rgba(0,0,0,.8) 100%);
                  z-index: 2;
                }

                .main-news__list-prew {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 1;

                  .main-news__list-prew-link {
                    img {
                      width: 100%;
                      height: 100%;
                      display: inline;
                      object-fit: cover;
                  }
                }
              }
              .news-info {
                position: relative;
                margin-top: auto;
                padding-bottom: 0;
                z-index: 3;
                .news-info__headline {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                }
                .news-footer {
                  order: 3;
                  margin-top: 1rem;
                }
              }
              }
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            &-prew {
              &-logo {
                width: 27.7rem;
                height: 18.6rem;
                object-fit: cover;
                vertical-align: 0;

                @media all and (max-width: 992px) {
                  width: 22rem;
                  height: 14.6rem;
                }
                @media all and (max-width: 576px) {
                  width: 18rem;
                  height: 11.6rem;
                }
                @media all and (max-width: 480px) {
                  display: none;
                }

              }
            }

            .news-info {
              padding: 1rem 1.5rem 1.5rem 2rem;
              display: flex;
              flex-direction: column;

              @media all and (max-width: 480px) {
                padding: 1rem 1.6rem;
              }

              &__headline {
                max-width: 46.7rem;
              }

              &__headline-link {
                color: #DADADA;
                transition: .2s;

                &:hover {
                  color: white;
                }
              }

              &__description {
                display: block;
                padding-top: .5rem;


                @media all and (max-width: 992px) {
                  margin-bottom: 1rem;
                }
                @media all and (max-width: 480px) {
                  display: none;
                }
              }

              .news-footer {
                margin-top: auto;
                display: flex;
                justify-content: space-between;
                padding-right: 1.5rem;

                @media all and (max-width: 480px) {
                  order: 1;
                  margin-bottom: .5rem;
                }

                &__comment {
                  display: flex;
                  align-items: center;

                  &-number {
                    padding-left: .5rem;
                  }

                  &-text {
                    padding-left: .4rem;
                  }
                }
              }
            }
          }
        }

        &__thematic-news {
          width: 28rem;
          margin-left: 2rem;

          .news-content {
            display: flex;
            flex-direction: column;

            &__filter-name {

              background: #F3B220;
              /* тень */
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 3px 3px 0px 0px;
              text-align: center;

              &-text {
                display: inline-block;
                padding: .8rem 0 .7rem;
              }
            }

            &__item {
              background: #1F1F1F;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 3px;
              margin-bottom: 1.5rem;

              &-prew {

                &-pic {
                  width: 100%;
                }
              }

              &-info {
                padding: 1rem;

                &-headline {
                }

                &-description {
                  display: inline-block;
                  padding-top: .5rem;
                }
              }
            }
          }
        }

      }

    }

    .show-more {
      display: flex;
      margin-top: 3.2rem;
      background: rgba(243, 178, 32, 0.75);
      border-radius: 2px;
      text-align: center;
      padding: 1.1rem 2rem;
      justify-content: space-between;
      margin-bottom: 13.6rem;

      @media all and (max-width: 992px) {
        margin: 2rem 0 9rem;
      }
      @media all and (max-width: 576px) {
        margin: 2rem 0 5rem;
      }
      @media all and (max-width: 480px) {
        width: max-content;
        margin: 0 auto 2.6rem;
        background: transparent;
        padding: 0 0 .5rem;
        font-weight: 400;
        color: #169494;
        border-bottom: 1px solid #FFBC25;
        text-align: center;
      }

      @media all and (max-width: 375px) {
        font-size: 12px;
        line-height: 14px;
      }

      &__pic {
        width: 21.58px;
        height: 21.58px;
        border: 3px solid #121212;
        box-sizing: border-box;
        transform: rotate(45deg);
        border-top: none;
        border-left: none;

        @media all and (max-width: 480px) {
          display: none;
        }
      }
    }
  }
}


@media all and (max-width: 1060px) {
  .content-news .news-info__tabcontent .news-container__thematic-news {
    display: none;
  }

  .content-news .news-info__tabcontent .news-container .main-news__list {
    max-width: unset;
  }

  .main-news {
    width: 100%;
  }
}

.news-survey {
  padding: 20px;

  &__title {
    margin-bottom: 15px;
    text-align: center;
    font-size: 22px;
  }

  &__choice {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.survey-choice-button {
  cursor: pointer;
  display: block;
  width: 100%;
  background: rgba(52, 52, 52, .7);
  border-radius: 6px;
  padding: 8px 16px;

  &__radio {
    display: none;
  }

  &:hover {
    background: rgba(52, 52, 52, 1);
  }
}

.survey-choice {
  display: flex;
  justify-content: space-between;
  background: rgba(52, 52, 52, .7);
  border-radius: 6px;
  padding: 8px 16px;
  position: relative;

  &__progress {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    border: none !important;
    background: rgba(52, 52, 52, .3);

    .ui-progressbar-value {
      background: rgba(52, 52, 52, 1);
      border: none;
    }
  }

  &__label {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  &__voted {
    color: rgba(218, 218, 218, .7);
    font-size: 14px;
    margin-left: 5px;
  }

  &__percent {
    position: relative;
    z-index: 2;
    font-weight: 500;
    font-size: 16px;
  }
}
